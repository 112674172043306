/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Paper,
  TableHead,
  Grid,
  CardActions,
  CardContent,
  Box,
  Modal,
  TextField,
  LinearProgress,
  IconButton,
  Tooltip,
  Backdrop,
  CircularProgress,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
  FormGroup,
  Snackbar,
  Skeleton,
  Alert,
  MenuItem,
  alpha,
  styled,
  Menu,
} from '@mui/material';
// components
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, Timestamp, updateDoc } from 'firebase/firestore';
import { fr } from 'date-fns/locale';
import { format } from 'date-fns';
import { db } from '../../firebase.config';
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../sections/@dashboard/user';
// mock
import USERLIST from '../../_mock/user';
import CustomButton from '../../components/CustomButton';

// ----------------------------------------------------------------------
// ----------- for more bouton modal
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 5,
    marginTop: theme.spacing(1),
    minWidth: 200,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 15,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));
// ----------------------------------------------------------------------




// for modal
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};
const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 400,
  bgcolor: 'white',
  border: '1px solid white',
  borderRadius: '7px',
  boxShadow: 0,
  p: 3,
};

export default function Discount() {

  const { eventID } = useParams();
  // loading
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();





  // modification de code
  const [summary, setSummary] = React.useState({}); // valeur de la reduction
  const [nomDuCode, setNom] = React.useState(''); // valeur de la reduction
  const [codeASaisir, setASaisir] = React.useState(''); // valeur de la reduction
  const [nbUtilisation, setNb] = React.useState(0); // valeur de la reduction
  const [reduction, setReduction] = React.useState(0); // valeur de la reduction
  const [debutField, setDebut] = React.useState(); // valeur de la reduction
  const [finField, setFin] = React.useState(); // valeur de la reduction
  const [limitedT, setLimit] = React.useState(false); //
  const [period, setPeriod] = React.useState(false); // activer un periode de validité
  const [reductionType, setReductionType] = React.useState('fixe'); // reduction type
  // recuperation de billets
  const [tickets, ticketList] = React.useState([]); // contient la liste des tickets
  const [checks, setCheck] = React.useState([]);

  // for change value of globla quota
  const [modif, setModif] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [refreh, setRefresh] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setModif(false);
    setRefresh((prev) => !prev);
  };

  //  snack bar
  const [openSnack, setOpenSnack] = React.useState(false);
  const [msg, setMsg] = React.useState({ content: '', color: '' });
  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  // setup des codes promo
  const [discounts, setDiscount] = useState([]);
  const [curent, setCurent] = useState();
  React.useEffect(() => {
    (async () => {
      setDiscount([]);
      try {
        setLoading(true);
        const q = query(collection(db, `evenement/${eventID}/codePromo`));
        const snapshot = await getDocs(q);
        snapshot.forEach((d) => {
          setDiscount((discounts) => [...discounts, d]);
          console.log(d.id, ' => ', d.data());
        });
        setLoading(false);
      } catch (error) {
        console.log('error');
      }
    })();
  }, [refreh, eventID]);

  React.useEffect(() => {
    (async () => {
      setDiscount([]);
      try {
        setLoading(true);
        const q = await getDoc(doc(db, `evenement/${eventID}/recette/`, eventID));
        if (q.exists()) {
          setSummary(q.data())
        }
        setLoading(false);
      } catch (error) {
        console.log('error');
      }
    })();
  }, [refreh, eventID]);

  const handleToggle = () => {
    setOpen(!open);
  };
  const handleVisible = async () => {
    const discount = discounts[selectedIndex];
    if (discount.data().statut === 0) {
      try {
        handleToggle();
        const statRef = doc(db, `evenement/${eventID}/codePromo`, `${discount.id}`);

        await updateDoc(statRef, {
          statut: 1,
        });
        setMsg({ content: 'Code promotionel actif' });
        setOpenSnack(true);
      } catch (error) {
        handleClose();
        console.log(error.message);
        setMsg({ content: 'une erreur est survenue', error: true });
        setOpenSnack(true);
      }
    } else if (discount.data().statut === 1) {
      try {
        handleToggle();
        const statRef = doc(db, `evenement/${eventID}/codePromo`, `${discount.id}`);
        await updateDoc(statRef, {
          statut: 0,
        });
        setMsg({ content: 'Code promotionel inactif' });
        setOpenSnack(true);
      } catch (error) {
        handleClose();
        console.log(error.message);
        setMsg({ content: 'une erreur est survenue', error: true });
        setOpenSnack(true);
      }
    }
    handleClose();
  };
  const handleModif = async (index) => {
    setLoading(true);
    setCurent(index);
    navigate(`${discounts[selectedIndex].id}`)
    try {
      // Remplissage des informations
      setNom(discounts[index].data().nom_code);
      setASaisir(discounts[index].data().code);
      setNb(discounts[index].data()?.usage.max);
      setReductionType(discounts[index].data().reduc.type);
      setReduction(discounts[index].data().reduc.value);
      if (discounts[index].data().billet.length !== 0) {
        setLimit(true);
        const q = query(collection(db, `evenement/${eventID}/tickets`));
        const snapshot = await getDocs(q);
        snapshot.forEach((d) => {
          ticketList((tickets) => [...tickets, d]);
          console.log(d.id);
          console.log(discounts[index].data().billet);
          const exist = discounts[index].data().billet.findIndex(({ b }) => `${b}` === `${d.id}`);
          console.log(exist);
          if (exist !== -1) {
            setCheck((checks) => [...checks, true]);
          } else {
            setCheck((checks) => [...checks, false]);
          }
        });
      } else {
        setLimit(false);
      }
      // recuperation de billet
    } catch (error) {
      console.log(error);
    }
    setModif(true);
    setCurent(index);
    setLoading(false);
  };
  const backModif = () => {
    setLoading(true);
    try {
      // Remplissage des informations
      setNom();
      setASaisir();
      setNb();
      setReductionType();
      setReduction();
      ticketList([]);
      setCheck([]);
      // recuperation de billet
    } catch (error) {
      console.log('error');
    }
    setModif(false);
    setCurent();
    setLoading(false);
  };
  // supression d'un billet
  const handleSupp = async () => {
    handleToggle();
    console.log('supp');
    const discount = discounts[selectedIndex];

    try {
      await deleteDoc(doc(db, `evenement/${eventID}/codePromo`, `${discount.id}`));
      setMsg({ content: 'Code promotionel supprimé avec success' });
      setOpenSnack(true);
    } catch (error) {
      console.log(error.message);
      setMsg({ content: 'Une erreur est survenue', error: true });
      setOpenSnack(true);
      handleClose();
    }

    handleClose();
    handleCloseDeleteModal();
  };

  const handleLimited = () => {
    setLimit((prev) => !prev);
  };
  const handlePeriod = () => {
    setPeriod((prev) => !prev);
  };

  // creer nos codes de promotion
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log('reduction ', reduction);
    const d = Date.now();
    try {
      // handleToggle();
      const tb = []; // tableau des billets selectionnes
      for (let index = 0; index < checks.length; index++) {
        const element = checks[index];
        if (element) {
          tb.push(tickets[index].id);
        }
      }
      console.log('checks : ', tb);

      if (debutField && finField) {
        // verification de la periode

        const element = discounts[curent];
        await updateDoc(doc(db, `evenement/${eventID}/codePromo`, `${discounts[curent].id}`), {
          // creationDate: name,
          code: codeASaisir,
          reduc: { type: reductionType, value: reduction },
          statut: 1,
          usage: { max: nbUtilisation, now: discounts[curent].data().usage.now },
          billet: tb,
          nom_code: nomDuCode,
          debut: Timestamp.fromDate(new Date(debutField)),
          fin: Timestamp.fromDate(new Date(finField)),
          createDate: Timestamp.fromDate(new Date(d)),
        });
      } else if (debutField) {
        const element = discounts[curent];
        console.log('elemnt2 ', element);
        await updateDoc(doc(db, `evenement/${eventID}/codePromo`, `${discounts[curent].id}`), {
          // creationDate: name,
          code: codeASaisir,
          reduc: { type: reductionType, value: reduction },
          statut: 1,
          usage: { max: nbUtilisation, now: discounts[curent].data().usage.now },
          billet: tb,
          nom_code: nomDuCode,
          debut: Timestamp.fromDate(new Date(debutField)),
          createDate: Timestamp.fromDate(new Date(d)),
        });
      } else if (finField) {
        const element = discounts[curent];
        console.log('elemnt3 ', element);
        await updateDoc(doc(db, `evenement/${eventID}/codePromo`, `${discounts[curent].id}`), {
          // creationDate: name,
          code: codeASaisir,
          reduc: { type: reductionType, value: reduction },
          statut: 1,
          usage: { max: nbUtilisation, now: discounts[curent].data().usage.now },
          billet: tb,
          nom_code: nomDuCode,
          debut: Timestamp.fromDate(Date.now()),
          fin: Timestamp.fromDate(new Date(finField)),
          createDate: Timestamp.fromDate(new Date(d)),
        });
      } else {
        const element = discounts[curent];
        console.log('elemnt4 ', element);
        await updateDoc(doc(db, `evenement/${eventID}/codePromo`, `${discounts[curent].id}`), {
          // creationDate: name,
          code: codeASaisir,
          reduc: { type: reductionType, value: reduction },
          statut: 1,
          usage: { max: nbUtilisation, now: discounts[curent].data().usage.now },
          billet: tb,
          nom_code: nomDuCode,
          createDate: Timestamp.fromDate(new Date(d)),
        });
      }
      setMsg({ content: 'Code promotionel modifié avec success', color: 'green' });
      setOpenSnack(true);
    } catch (error) {
      console.log('erro');
      console.log(error.message);
      setMsg({ content: 'Une erreur est survenue', color: 'red' });
      setOpenSnack(true);
    }
    setLoading(false);
    // navigate(-1, { replace: true });
  };

  // gestion du radio boutton permettant de selectionner le type de reduction
  const handleRadioChange = (event) => {
    setReductionType(event.target.value);
  };

  // gestion du checkBox
  const handleCheckChange = (e) => {
    const tmp = checks;
    tmp.splice(e, 1, !checks[e]);
    setCheck(tmp);
    console.log(checks);
  };

  const handleTimestamp = (e) => {
    const date = e.toDate();
    const tmp = date.toJSON().split('.');
    const t = tmp[0].split('T');
    return t[0];
  };

  const [selectedIndex, setSelectedIndex] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const handleOpenMenu = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
  };
  const handleCloseMenu = (e) => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  // Modal deleted
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  return (
    <Page title="Code Promotion">
      <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {loading && <LinearProgress />}

      <Modal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ borderRadius: '7px' }}
      >
        <Box sx={styleModal}>
          <Typography variant="h6">Supprimer cet code de promotion</Typography>
          <Box sx={{ marginTop: 5, p: 2 }} dir="ltr">
            <Alert severity="error">
              Cette action entraînera la suppression définitive le code de promotion.
            </Alert>
          </Box>
          <Grid container spacing={2} justifyContent="end" paddingTop={5}>
            <Grid item>
              <Button
                variant="outlined"
                sx={{
                  textTransform: 'initial',
                  border: '2px solid transparent',
                  color: 'black',
                  backgroundColor: 'white',
                  borderRadius: '7px',
                  borderColor: 'black',
                  '&:hover': {
                    backgroundColor: 'black',
                    color: 'white',
                    borderColor: 'black',
                  },
                }}
                onClick={handleCloseDeleteModal}
              >
                Annuler
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                sx={{
                  textTransform: 'initial',
                  border: '2px solid transparent',
                  Color: 'white',
                  backgroundColor: 'red',
                  borderRadius: '7px',
                  '&:hover': {
                    backgroundColor: 'white',
                    color: 'red',
                    borderColor: 'red',
                  },
                }}
                onClick={() => handleSupp()}
              >
                Supprimer
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {!modif ? (
        <Container sx={{ marginTop: { xs: 3 } }}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignItems={{ xs: 'start', sm: 'center' }}
            justifyContent="space-between"
            mb={5}
            spacing={2}
          >
            <Typography variant="h3" gutterBottom>
              Code de promotion <br />
              <Typography variant="body2">
                Les codes de promotion vous permettent de créer des opérations ciblées dans le temps ou réservées à certains de vos participants.
              </Typography>
            </Typography>
          </Stack>

          <Stack spacing={3}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={4}>
                <Card sx={{ padding: 3 }}>
                  <Stack>
                    <Typography variant='body2'>Coût Total</Typography>
                    <Typography variant='subtitle1'>{summary.costPromoCode > 0 ? `${summary.costPromoCode} CFA` : '-'}</Typography>
                  </Stack>
                </Card>
              </Grid>
              <Grid item xs={6}  sm={4}>
                <Card sx={{ padding: 3 }}>
                  <Stack>
                    <Typography variant='body2'>Revenu Total</Typography>
                    <Typography variant='subtitle1'>{summary.gainPromoCode > 0 ? `${summary.gainPromoCode} CFA` : '-'}</Typography>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <CustomButton type='primaire' buttonText={'Ajouter un code promo'} component={RouterLink}
                to="ajout" />
            </Box>



            <Stack spacing={2}>
              {(discounts.length === 0 && !loading) && <Stack spacing={3} padding={2} justifyContent='center' alignItems={'center'} sx={{ border: '2px solid gainsboro', borderRadius: '10px' }}>
                <Iconify icon={'mdi-light:gift'} sx={{ height: 50, width: 50 }} />
                <Stack justifyContent='center' alignItems={'center'}>
                  <Typography variant='subtitle1'>Pas de code promo disponible</Typography>
                  <Typography variant='body2'>Vos codes promo apparaitront ici une fois créés</Typography>
                </Stack>
                <Box>
                  <CustomButton type='primaire' buttonText={'Ajouter un code promo'} component={RouterLink}
                    to="ajout" />
                </Box>

              </Stack>}
              {discounts.map((row, index) => (
                <Grid key={row.data().name} container padding={2} sx={{ display: 'flex', alignItems: 'center', bgcolor: '#f2f2f2', borderRadius: '10px' }}>
                  <Grid item xs={12} sm={4}>
                    <Stack>
                      <Stack direction={'row'} spacing={1}>
                        <Typography variant='h6'>{row.id}</Typography>
                        {
                          row.data().statut === 0 &&

                          <Box sx={{ bgcolor: '#FFD6D6', borderRadius: '20px', paddingX: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography fontSize={11} sx={{ color: '#720000' }} variant='caption'>Inactif</Typography>
                          </Box>
                        }

                      </Stack>

                      <Typography variant='subtitle1'>{row.data().reduc.value}
                        {row.data().reduc.type === 0 ? '%' : 'CFA'} de reduction</Typography>
                        {row.data()?.billet?.length > 0 ? <Typography variant='caption'>Applicable à certains billets</Typography> : <Typography variant='caption'>Applicable à tous les billets</Typography>}
                      
                    </Stack>
                  </Grid>
                  <Grid item xs={3} sm={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Stack>
                      <Typography textAlign={'center'} variant='body2'>Utilisation</Typography>
                      <Typography textAlign={'center'} variant='subtitle1'>{row.data()?.now}/{row.data()?.max}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={3} sm={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Stack>
                      <Typography textAlign={'center'} variant='body2'>Coût</Typography>
                      <Typography textAlign={'center'} variant='subtitle1'>{row.data().cost > 0 ? `${row.data().cost} CFA` : '-'}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={3} sm={2} sx={{ display: 'flex', justifyContent: 'center', alignItems:'center' }}>
                    <Stack>
                      <Typography textAlign={'center'} variant='body2'>Revenu</Typography>
                      <Typography textAlign={'center'} variant='subtitle1'>{row.data().gain > 0 ? `${row.data().gain} CFA` : '-'}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={3} sm={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <IconButton key={index} onClick={(e) => handleOpenMenu(e, index)}
                      aria-controls={openMenu ? 'demo-customized-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openMenu ? 'true' : undefined} sx={{ border: '1px solid gainsboro' }}>
                      <Iconify icon="material-symbols-light:more-vert" />
                    </IconButton>
                    <StyledMenu
                      id="demo-customized-menu"
                      MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                      }}
                      anchorEl={anchorEl}
                      open={openMenu}
                      onClose={handleCloseMenu}
                    >

                      <MenuItem key={`${index}-1`} onClick={(e) => {
                        handleCloseMenu();
                        handleModif(selectedIndex); // Utilise l'index sélectionné
                      }} disableRipple value={1}>
                        <Typography variant='body1'>Editer</Typography>
                      </MenuItem>

                      {
                        discounts[selectedIndex]?.data().statut === 1 ?
                          <MenuItem
                            onClick={(e) => {
                              handleCloseMenu();
                              handleVisible()
                            }}
                            disableRipple
                            value={2}
                          >
                            <Typography variant='body1'>Désactiver</Typography>
                          </MenuItem>
                          : discounts[selectedIndex]?.data().statut === 0 ?
                            <MenuItem
                              onClick={(e) => {
                                handleCloseMenu();
                                handleVisible()
                              }}
                              disableRipple
                              value={2}
                            >
                              <Typography variant='body1'>Activer</Typography>
                            </MenuItem> : null
                      }
                      <MenuItem
                        onClick={(e) => {
                          handleCloseMenu();
                          handleOpenDeleteModal()
                        }}
                        disableRipple
                        value={2}
                      >
                        <Typography variant='body1'>Supprimer</Typography>
                      </MenuItem>

                    </StyledMenu>
                  </Grid>
                </Grid>
              ))}
            </Stack>
          </Stack>


        </Container>
      ) : (
        <Container sx={{ marginTop: { xs: 3 } }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Modifier un code de promotion
            </Typography>
          </Stack>
          <form onSubmit={handleSubmit}>
            <Card>
              {/* saisir ou generation de de code */}
              <Box sx={{ p: 3 }} dir="ltr">
                <Grid alignItems="center" container spacing={3} justifyContent="space-between">
                  <Grid item xs={10} sm={6} md={6}>
                    <Typography variant="h6">Informations sur le code</Typography>
                  </Grid>
                </Grid>

                <Box sx={{ p: 2, bgcolor: '#f2f2f2', borderRadius: '7px' }} dir="ltr">
                  <Grid paddingY={1} alignItems="center" container spacing={3}>
                    <Grid item xs={12} sm={4} md={4}>
                      <Typography>Nom du code</Typography>
                    </Grid>

                    <Grid item xs={12} sm={8} md={6}>
                      <TextField
                        label="Entrer le nom du code"
                        type="text"
                        value={nomDuCode}
                        onChange={(e) => setNom(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid paddingY={1} alignItems="center" container spacing={3}>
                    <Grid item xs={12} sm={4} md={4}>
                      <Typography>code à saissir</Typography>
                    </Grid>

                    <Grid item xs={12} sm={8} md={6}>
                      <TextField
                        label="Entrer le code à saisir"
                        type="text"
                        value={codeASaisir}
                        onChange={(e) => setASaisir(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid paddingY={1} alignItems="center" container spacing={3}>
                    <Grid item xs={12} sm={4} md={4}>
                      <Typography>nombre d'utilisation</Typography>
                    </Grid>

                    <Grid item xs={12} sm={8} md={6}>
                      <TextField
                        label="Entrer le nombre d'utilisation"
                        type="text"
                        value={nbUtilisation}
                        onChange={(e) => setNb(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              {/* Type de reduction */}
              <Box sx={{ p: 3 }} dir="ltr">
                <Grid alignItems="center" container spacing={3}>
                  <Grid item xs={10} sm={4} md={4}>
                    <Typography variant="h6">Type de réduction </Typography>
                  </Grid>
                </Grid>

                <Box sx={{ p: 2, bgcolor: '#f2f2f2', borderRadius: '7px' }} dir="ltr">
                  <FormControl fullWidth>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      value={reductionType}
                      onChange={handleRadioChange}
                    >
                      <Grid alignItems="center" container spacing={3}>
                        <Grid item xs={10} sm={4} md={4}>
                          <Typography>Réduction fixe en CFA</Typography>
                        </Grid>
                        <Grid item xs={2} sm={8} md={8}>
                          <FormControlLabel value="fixe" control={<Radio />} />
                        </Grid>
                      </Grid>
                      {/* formulaire reduction fixe */}
                      {reductionType === 'fixe' && (
                        <Box sx={{ backgroundColor: 'gainsboro', p: 3, borderRadius: '7px' }} dir="ltr">
                          <Grid paddingY={1} alignItems="center" container spacing={3}>
                            <Grid item xs={12} sm={4} md={4}>
                              <Typography>Montant de la réduction</Typography>
                            </Grid>

                            <Grid item xs={12} sm={8} md={6}>
                              <TextField
                                label="montant de la reduction"
                                required
                                name="nom"
                                type="number"
                                value={reduction}
                                onChange={(e) => setReduction(e.target.value)}
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                      <Grid alignItems="center" container spacing={3}>
                        <Grid item xs={10} sm={4} md={4}>
                          <Typography>Reduction en pourcentage</Typography>
                        </Grid>
                        <Grid item xs={2} sm={8} md={8}>
                          <FormControlLabel value="percent" control={<Radio />} />
                        </Grid>
                      </Grid>
                      {/* formulaire pourcentage */}
                      {reductionType === 'percent' && (
                        <Box sx={{ backgroundColor: 'gainsboro', p: 3, borderRadius: '7px' }} dir="ltr">
                          <Grid paddingY={1} alignItems="center" container spacing={3}>
                            <Grid item xs={12} sm={4} md={4}>
                              <Typography>Montant de la réduction</Typography>
                            </Grid>

                            <Grid item xs={12} sm={8} md={6}>
                              <TextField
                                label="montant de la reduction"
                                required
                                name="nom"
                                type="number"
                                value={reduction}
                                onChange={(e) => setReduction(e.target.value)}
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Box>

              {/* choix du Ticket */}
              <Box sx={{ p: 3 }} dir="ltr">
                <Grid alignItems="center" container spacing={3}>
                  <Grid item xs={10} sm={4} md={4}>
                    <Typography variant="h6">Tickets</Typography>
                  </Grid>
                </Grid>

                <Box sx={{ p: 2, bgcolor: '#f2f2f2', borderRadius: '7px' }} dir="ltr">
                  <Grid alignItems="center" container spacing={3}>
                    <Grid item xs={10} sm={4} md={4}>
                      <Typography> Limiter à certains tickets</Typography>
                    </Grid>
                    <Grid item xs={2} sm={8} md={8}>
                      <FormControlLabel control={<Switch checked={limitedT} onChange={handleLimited} />} />
                    </Grid>
                  </Grid>
                  {limitedT && (
                    <Box sx={{ p: 2 }} dir="ltr">
                      <FormGroup>
                        {tickets.map((ticket, index) => (
                          <FormControlLabel
                            key={index}
                            control={
                              checks[index] ? (
                                <Checkbox checked onClick={() => handleCheckChange(index)} />
                              ) : (
                                <Checkbox onClick={() => handleCheckChange(index)} />
                              )
                            }
                            label={ticket.data().ticket_name}
                          />
                        ))}
                      </FormGroup>
                    </Box>
                  )}
                </Box>
              </Box>

              {/* periode de validite */}
              <Box sx={{ p: 3 }} dir="ltr">
                <Grid alignItems="center" container spacing={3}>
                  <Grid item xs={10} sm={4} md={4}>
                    <Typography variant="h6">Période de validité </Typography>
                  </Grid>
                </Grid>

                <Box sx={{ p: 2, bgcolor: '#f2f2f2', borderRadius: '7px' }} dir="ltr">
                  <Grid alignItems="center" container spacing={3}>
                    <Grid item xs={10} sm={4} md={4}>
                      <Typography>
                        Définir une période <br />
                        <Typography variant="caption">Par defaut : pour toute l'année</Typography>
                      </Typography>
                    </Grid>
                    <Grid item xs={2} sm={8} md={8}>
                      <FormControlLabel control={<Switch checked={period} onChange={handlePeriod} />} />
                    </Grid>
                  </Grid>
                  {period && (
                    <Box sx={{ backgroundColor: 'gainsboro', p: 3, borderRadius: '7px' }} dir="ltr">
                      <Grid paddingY={1} alignItems="center" container spacing={3}>
                        <Grid item xs={12} sm={4} md={4}>
                          <Typography>Date debut</Typography>
                        </Grid>

                        <Grid item xs={12} sm={8} md={6}>
                          <TextField
                            label="Entrer la date de debut"
                            id="datetime-local"
                            type="datetime-local"
                            value={debutField}
                            onChange={(e) => {
                              setDebut(e.target.value);
                            }}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid paddingY={1} alignItems="center" container spacing={3}>
                        <Grid item xs={12} sm={4} md={4}>
                          <Typography>Date de fin</Typography>
                        </Grid>

                        <Grid item xs={12} sm={8} md={6}>
                          <TextField
                            label="Entrer la date de fin"
                            id="datetime-local"
                            type="datetime-local"
                            value={finField}
                            onChange={(e) => {
                              setFin(e.target.value);
                            }}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Box>
              </Box>

              <Box sx={{ p: 3 }} dir="ltr">
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={2} md={2}>
                    <Button
                      fullWidth
                      sx={{
                        textTransform: 'initial',
                        border: '2px solid transparent',
                        color: 'black',
                        backgroundColor: 'white',
                        borderRadius: '7px',
                        borderColor: 'black',
                        '&:hover': {
                          backgroundColor: 'black',
                          color: 'white',
                          borderColor: 'black',
                        },
                      }}
                      variant="outlined"
                      onClick={() => backModif()}
                    >
                      Annuler
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={2} md={2}>
                    <Button
                      fullWidth
                      variant="contained"
                      sx={{
                        textTransform: 'initial',
                        border: '2px solid transparent',
                        Color: 'white',
                        backgroundColor: '#5E17EB',
                        borderRadius: '7px',
                        '&:hover': {
                          backgroundColor: 'white',
                          color: '#5E17EB',
                          borderColor: '#5E17EB',
                        },
                      }}
                      type="submit"
                    >
                      Enregister
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </form>
        </Container>
      )}
      <Snackbar
        open={openSnack}
        autoHideDuration={5000}
        onClose={handleCloseSnack}
        message={
          <Box>
            {msg.content}{' '}
            <Iconify
              sx={{ color: msg.error ? 'red' : 'green' }}
              icon={`${msg.error ? 'system-uicons:cross-circle' : 'mdi:success-circle-outline'}`}
            />
          </Box>
        }
      />
    </Page>
  );
}
