

  import { useState, useEffect } from 'react';
  import PropTypes from 'prop-types';
  import { NavLink as RouterLink, matchPath, useLocation, useParams } from 'react-router-dom';
  // material
  import { alpha, useTheme, styled } from '@mui/material/styles';
  import { Box, List, Collapse, ListItemText, ListItemIcon, ListItemButton, Divider } from '@mui/material';
  //
  import { doc, getDoc } from 'firebase/firestore';
  import Iconify from './Iconify';
  import { db } from '../firebase.config';
  
  // ----------------------------------------------------------------------
  
  const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    position: 'relative',
    textTransform: 'initial',
    color: 'white',
    borderRadius: '7px',
  }));
  
  const ListTitleStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    position: 'relative',
    textTransform: 'uppercase',
    color: 'white',
    fontWeight:'bold',
    borderRadius: theme.shape.borderRadius,
  }));
  
  const ListItemIconStyle = styled(ListItemIcon)({
    width: 22,
    height: 22,
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  });
  
  // ----------------------------------------------------------------------
  
  NavItem.propTypes = {
    item: PropTypes.object,
    active: PropTypes.func,
  };
  
  function NavItem({ item, active }) {
    const theme = useTheme();
  
    const isActiveRoot = active(item.path);
  
    const { title, path, icon, info, children } = item;
  
    const [open, setOpen] = useState(isActiveRoot);
  
    const handleOpen = () => {
      setOpen((prev) => !prev);
    };
  
    /*
    categorie
    */
    const [openCategories, setOpenCategories] = useState({category1: false, category2: false });
  
    const handleCategoryToggle = (category) => {
      setOpenCategories((prevOpen) => ({
        ...prevOpen,
        [category]: !prevOpen[category],
      }));
    };
  
    /*
    categorie
    */
  
    const activeRootStyle = {
      color: 'white',
      fontWeight: 'fontWeightMedium',
      bgcolor: '#141414',
      '&:hover': {
        bgcolor: '#141414',
        color:'white'
      },
    };
  
    const activeSubStyle = {
      color: 'text.primary',
      fontWeight: 'fontWeightMedium',
      
    };
  
  
    if (children) {
      return (
        <>
          <ListItemStyle
            onClick={handleOpen}
            sx={{
              ...(isActiveRoot && activeRootStyle),
            }}
          >
            <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
            <ListItemText disableTypography primary={title} />
            {info && info}
            <Iconify
              icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
              sx={{ width: 16, height: 16, ml: 1 }}
            />
          </ListItemStyle>
  
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children.map((item) => {
                const { title, path } = item;
                const isActiveSub = active(path);
  
                return (
                  <ListItemStyle
                    key={title}
                    component={RouterLink}
                    to={`${path}`}
                    sx={{
                      ...(isActiveSub && activeSubStyle),
                    }}
                  >
                    <ListItemIconStyle>
                      <Box
                        component="span"
                        sx={{
                          width: 4,
                          height: 4,
                          display: 'flex',
                          borderRadius: '50%',
                          alignItems: 'center',
                          justifyContent: 'center',
                          bgcolor: 'text.disabled',
                          transition: (theme) => theme.transitions.create('transform'),
                          ...(isActiveSub && {
                            transform: 'scale(2)',
                            bgcolor: 'primary.main',
                          }),
                        }}
                      />
                    </ListItemIconStyle>
                    <ListItemText disableTypography primary={title} />
                  </ListItemStyle>
                );
              })}
            </List>
          </Collapse>
        </>
      );
    }
  
    let result = null
    if(path === ''){
      result = (
        
        <ListTitleStyle
        >
          <ListItemText disableTypography primary={title} />
          {info && info}
        </ListTitleStyle>
      );
    }
    else{
      result = (
        <ListItemStyle
          component={RouterLink}
          to={`/${path}`} 
          sx={{
            ...(isActiveRoot && activeRootStyle), '&:hover': {
              bgcolor: '#141414',
              color:'white'
            },
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
        </ListItemStyle>
      );
    }
    return result;
  }
  
  NavSectionMetrics.propTypes = {
    navConfig: PropTypes.array,
  };
  
  export default function NavSectionMetrics({ navConfig, ...other }) {
    const { eventID } = useParams();
    const [seance, isSeance] = useState(false)
    useEffect(() => {
      (async () => {
        try {
          // event
          const docRef0 = doc(db, 'evenement', eventID);
          const docSnap = await getDoc(docRef0);
          if (docSnap.exists()) {
            console.info(docSnap.data().seanceActive);
            if (docSnap.data().seanceActive === 1) {
              isSeance(true)
            } else {
              isSeance(false)
            }
          }
          
         console.error(navConfig)
        } catch (error) {
          console.log(error);
        }
      })();
    }, []);
  
    const { pathname } = useLocation();
  
    // const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);
    const contain = (path) =>{
      if(pathname.includes(path)){
        return true
      }
        return false;
    }
  
    return (
      <Box {...other}>
        <List disablePadding sx={{ p: 1 }}>
          {navConfig.map((item) => (
            <>
            {
              item.title ==="Seances" && !seance ? null :
              <NavItem key={item.title} item={item} active={contain} />
            }
            </>
            
          ))}
        </List>
      </Box>
    );
  }