import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, Navigate, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  IconButton,
  ListItemIcon,
  ListItemText,
  Link,
} from '@mui/material';
// components
import { doc, getDoc } from 'firebase/firestore';
import Iconify from '../../components/Iconify';
import { db } from '../../firebase.config';
import MenuPopover from '../../components/MenuPopover';
// mocks_
import account from '../../_mock/account';
import { UserAuth } from '../../context/AuthContext';
import useResponsive from '../../hooks/useResponsive';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Compte',
    icon: 'codicon:account',
    linkTo: '/settings/account',
  },
  {
    label: 'Membre d\'équipe',
    icon: 'codicon:account',
    linkTo: '/settings/account',
  },
  {
    label: "Centre d'aide",
    icon: 'oui:help',
    linkTo: '/organisation/app',
  },
];

const MENU_OPTIONS_MOBILE = [
  {
    label: 'Compte',
    icon: 'codicon:account',
    linkTo: '/settings/account',
  },
  {
    label: 'Mes évenements',
    icon: 'mdi-light:calendar',
    linkTo: '/bo',
  },
  {
    label: 'Mon organisation',
    icon: 'fluent:home-20-regular',
    linkTo: '/organisation/app',
  },
  {
    label: 'Marketing',
    icon: 'carbon:ibm-cloud-pak-network-automation',
    linkTo: '/marketing',
  },
  {
    label: "Centre d'aide",
    icon: 'oui:help',
    linkTo: 'https://support.choptonticket.com/fr',
  },
];

// ----------------------------------------------------------------------

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: '#5E17EB',
    },
    children: `${name.split(' ')[0][0]}`,
  };
}

export default function AccountPopover() {
  const { user, logOut } = UserAuth();
  const anchorRef = useRef(null);
  const [username, setUsername] = useState('');
  const [userInfo, setUser] = useState();
  const [org, setOrg] = useState();
  const [logo, setLogo] = useState();

  const navigate = useNavigate();

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef0 = doc(db, 'users', `${user.uid}`);
        // setLoading(true);
        const docSnap = await getDoc(docRef0);
        if (docSnap.exists()) {
          setUsername(`${docSnap.data().nom}  ${docSnap.data().prenom}`.toUpperCase());
          setUser(docSnap.data());
          setLogo(docSnap.data().idVisuel);
          console.log(docSnap.data());
          const promoterRef0 = doc(db, 'organisateurs', `${user.uid}`);
          const promoterSnap = await getDoc(promoterRef0);
          const docRef1 = doc(db, 'organisations', `${promoterSnap.data().idOrg}`);
          const docSnap1 = await getDoc(docRef1);
          if (docSnap1.exists()) {
            setOrg(docSnap1.data());
            console.log(docSnap1.data());
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData().catch(console.error);
  }, [user]);

  // log out function
  const handleLogout = async () => {
    try {
      await logOut();
      navigate('/login', { replace: true });
    } catch (error) {
      console.log(error);
    }
    setOpen(null);
  };
  const smUp = useResponsive('up', 'sm');
  const mdUp = useResponsive('up', 'md');

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          borderRadius: '35%',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '35%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[400], 0.8),
            },
          }),
        }}
      >
        {logo ? (
          <Stack direction="row" spacing={1} padding={1} alignItems="center">
            <Avatar src={logo} />
          </Stack>
        ) : (
          <Stack direction="row" spacing={1} padding={1} alignItems="center">
            {username && <Avatar {...stringAvatar(`${username}`)} />}
          </Stack>
        )}
      </IconButton>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          width: 250,
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >

        <Stack sx={{ p: 1 }}>
          <Box padding={1}>
            <Typography noWrap variant="body2">
              <strong>{userInfo && userInfo.email}</strong>
            </Typography>
          </Box>
          <Divider sx={{ borderStyle: 'dashed' }} />
          <Stack direction='row' alignItems='center'>
            <Box>
              {logo ? (
                <Stack direction="row" spacing={1} padding={1} alignItems="center">
                  <Avatar src={logo} />
                </Stack>
              ) : (
                <Stack direction="row" spacing={1} padding={1} alignItems="center">
                  {username && <Avatar {...stringAvatar(`${username}`)} />}
                </Stack>
              )}
            </Box>
            <Box sx={{ m: 1 }}>
              <Typography noWrap variant="body2" paddingBottom={0}>
                {`${userInfo && userInfo.nom} ${userInfo && userInfo.prenom}`}
              </Typography>
              <Typography noWrap variant="caption">
                <Link
                  href="/organisation/app"
                  underline="hover"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'black',
                    '&:hover': {
                      color: '#5E17EB',
                    },
                  }}
                >
                  <Typography variant='caption' noWrap>{org && org.nomOrg}</Typography>

                </Link>
              </Typography>
            </Box>
          </Stack>

          <Divider sx={{ borderStyle: 'dashed' }} />
          {
            mdUp ?
              <>
                {MENU_OPTIONS.map((option) => (
                  <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
                    <ListItemIcon>
                      <Iconify icon={option.icon} />
                    </ListItemIcon>
                    <ListItemText sx={{ fontSize: 14 }}><Typography variant='body2'>{option.label}</Typography></ListItemText>
                  </MenuItem>
                ))}</> :
              <>
                {MENU_OPTIONS_MOBILE.map((option) => (
                  <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
                    <ListItemIcon>
                      <Iconify icon={option.icon} />
                    </ListItemIcon>
                    <ListItemText sx={{ fontSize: 14 }}><Typography variant='body2'>{option.label}</Typography></ListItemText>
                  </MenuItem>
                ))}</>

          }

        </Stack>



        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1, color: 'red' }}>
          <ListItemIcon>
            <Iconify sx={{ color: 'red', height: 20, width: 20 }} icon="material-symbols-light:logout" />
          </ListItemIcon>
          <ListItemText><Typography variant='body2'>Deconnexion</Typography></ListItemText>
        </MenuItem>
      </MenuPopover>
    </>
  );
}
