/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
import { faker } from '@faker-js/faker';
// @mui
import React, { useEffect } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  Card,
  Box,
  Stack,
  Button,
  TextField,
  Divider,
  FormControlLabel,
  Switch,
  CardMedia,
  FormControl,
  Select,
  MenuItem,
  LinearProgress,
  Backdrop,
  CircularProgress,
  Link,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Alert,
  Autocomplete,
  Chip,
  CardContent,
} from '@mui/material';

// components

import { collection, doc, Firestore, getDoc, getDocs, query, Timestamp, updateDoc, where } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';
import { fr } from 'date-fns/locale';
import { format } from 'date-fns';
import Confetti, { ReactConfetti } from 'react-confetti';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { db, storage } from '../firebase.config';

import Page from '../components/Page';
import Iconify from '../components/Iconify';
import CustomButton from '../components/CustomButton';
import CheckCard from '../components/CheckCard';
import Sharefb from '../assets/images/Share_Event_FB.png';
import ShareTikTok from '../assets/images/Share_Tiktok.png';
import EmailMarketing from '../assets/images/email_marketing.png';

// sections

// ----------------------------------------------------------------------

export default function Publish() {
  // rich text
  const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());

  const affiche = () => {
    console.log('salut');
    console.log(editorState.getCurrentContent());
  };
  const theme = useTheme();
  const { eventID } = useParams();
  const [events, setEvents] = React.useState([]);
  const [description, setDescription] = React.useState([]);
  const [desc, setDesc] = React.useState('');
  const [tickets, setTickets] = React.useState([]);
  const [operateurs, setOperateurs] = React.useState([]);
  const [visibilite, setVisibilite] = React.useState(0);
  const [publiée, setPublish] = React.useState(false);
  const navigate = useNavigate();

  const createEventList = (doc) => {
    setEvents((events) => [...events, doc]); // ajouter a la fin
  };
  const [open, setOpen] = React.useState(false);
  const [refreh, setRefresh] = React.useState(false);

  const setVisible = () => {
    setVisibilite(0);
  };

  const setPrivate = () => {
    setVisibilite(1);
  };

  React.useEffect(() => {
    (async () => {
      try {
        setEvents([]);
        setTickets([]);
        setLoading(true);

        const docRef0 = doc(db, 'evenement', eventID);
        const docSnap = await getDoc(docRef0);

        if (docSnap.exists()) {
          createEventList(docSnap.data());
          // recupération des tickets
          const q = query(collection(db, `evenement/${docSnap.id}/tickets`));
          const snapshot = await getDocs(q);
          snapshot.forEach((d) => {
            console.log(d.data());
            setTickets((tickets) => [...tickets, d.data()]);
          });

          // recupération des operateurs

          // recupération des seances
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    })();
  }, [refreh]);

  // upload functions

  const [selectedImage, setSelectedImage] = React.useState();
  const [imageUrl, setImageUrl] = React.useState();

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  // --------------------

  // loading
  const [loading, setLoading] = React.useState(false);

  // convert timestamp

  const handleTimestamp = (e) => {
    const date = e.toDate();
    const tmp = date.toJSON().split('.');
    return tmp[0];
  };

  // fonction de mise à jour

  const handleToggle = () => {
    setOpen(!open);
  };

  const handlePublication = async () => {
    try {
      setLoading(true);
      if (tickets.length !== 0) {
        const refEvent = doc(db, `evenement`, eventID);
        const docSnap = await getDoc(refEvent);
        if (docSnap.exists()) {
          await updateDoc(refEvent, {
            status: parseInt(1),
            visibility: parseInt(visibilite),
          });
          setPublish(!publiée);
        }
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const allTags = ['Concert', 'Reggae', 'Zouk', 'DJ', 'Hip-hop', 'Pop', 'Electro', 'Jazz', 'Blues', 'Rap', 'RnB'];
  const [selectedTags, setSelectedTags] = React.useState([]);
  const [error, setError] = React.useState('');

  const handleTagChange = (event, value) => {
    if (value.length <= 10) {
      setSelectedTags(value);
      setError('');
    } else {
      setError('Vous ne pouvez sélectionner que 10 tags au maximum.');
    }
  };

  return (
    <Page title="Publication" >
      <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {loading && <LinearProgress />}
      {events.map((event) =>
        !publiée ? (
          <>
            <Container key={event.nomEvent} sx={{ marginTop: { xs: 3 }, bgcolor: 'white' }}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h4">
                  Publication de votre événement
                  <br />
                  <Typography variant="subtitle1">
                    Vous êtes à un clic de publier votre événement ! Prenez le temps de vérifier vos paramètres.
                  </Typography>
                </Typography>
              </Stack>


              <Grid container padding={2} paddingY={5} spacing={5}>
                <Grid item xs={12} sm={4}>
                  <Card sx={{borderRadius:'10px'}}>
                    <CardMedia component="img" height={200} image={`${event.idVisuel}`} alt="green iguana" />
                  </Card>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Box sx={{ height: '100%', padding: 2, borderRadius: '10px', border: '2px solid gainsboro' }}>
                    <Typography variant="h5">{event.nomEvent}</Typography>

                    <Typography variant="body2">
                      {event.type}
                    </Typography>

                    <Stack spacing={2}>
                      <Typography variant='body1'>
                        <strong>
                          {format(new Date(handleTimestamp(event.dateDebut)), 'EEE dd MMM yyyy HH:mm', { locale: fr })} -{' '}
                          {format(new Date(handleTimestamp(event.dateFin)), 'EEE dd MMM yyyy hh:mm', { locale: fr })}{' '}
                        </strong>
                      </Typography>
                      <Stack direction="row" spacing={1}>
                        <Typography variant='body2'>
                          {event.lieu_event}, {event.city}, {event.country}{' '}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
              <Box sx={{ height: '100%', padding: 2, borderRadius: '10px', border: '2px solid gainsboro', marginX: 2 }}>
                <Typography variant="h5">Billets</Typography>
                <Grid paddingTop={2} container >
                  <Grid item xs={6} sm={4} sx={{ padding: 3, borderRadius: '10px', bgcolor: '#f2f2f2', }}>
                    <Typography variant='body1'><strong>billet en vente</strong> </Typography>
                  </Grid>
                </Grid>
                <Stack spacing={1} marginY={2} sx={{ padding: 2, borderRadius: '10px', bgcolor: '#f2f2f2' }}>
                  <Typography variant='body2'>
                    Un mail sera envoyé à vos partenaires de vente
                  </Typography>
                </Stack>
                <Stack spacing={1} marginY={2} sx={{ padding: 2, borderRadius: '10px', bgcolor: '#f2f2f2' }}>
                  <Typography variant='body2'>
                    Envoyer un notification a tous vos abonnées
                  </Typography>
                </Stack>
              </Box>
              <Box sx={{ height: '100%', padding: 2, borderRadius: '10px', border: '2px solid gainsboro', margin: 2 }}>
                <Typography variant="h5">Visibilité</Typography>
                <Typography variant="body2">Votre événement est-il public ou privé ?</Typography>
                <Stack direction='row' spacing={1} marginY={2}>
                  <Box
                    onClick={setVisible}
                    component="button"
                    padding={1}
                    color={visibilite === 0 ? '#7E1F86' : null}
                    sx={visibilite === 0 ? {
                      cursor: 'pointer',
                      borderRadius: '5px',
                      fontSize: 10,
                      border: '0.5px solid #B991DE',
                    } : {
                      cursor: 'pointer',
                      borderRadius: '5px',
                      fontSize: 10,
                      border: '0.5px solid gainsboro',
                    }}
                  >
                    <Stack direction='row' spacing={1} alignItems='center'>
                      <Iconify icon='ic:outline-public' sx={{ height: 20, width: 20 }} />
                      <Typography variant='body2'><strong>Public</strong></Typography>
                    </Stack>

                  </Box>
                  <Box
                    onClick={setPrivate}
                    component="button"
                    padding={1}
                    color={visibilite === 1 ? '#7E1F86' : null}
                    sx={visibilite === 1 ? {
                      cursor: 'pointer',
                      borderRadius: '5px',
                      fontSize: 10,
                      border: '0.5px solid #B991DE',
                    } : {
                      cursor: 'pointer',
                      borderRadius: '5px',
                      fontSize: 10,
                      border: '0.5px solid gainsboro',
                    }}
                  >
                    <Stack direction='row' spacing={1} alignItems='center'>
                      <Iconify icon='mdi:lock' sx={{ height: 20, width: 20 }} />
                      <Typography variant='body2'><strong>Privé</strong></Typography>
                    </Stack>
                  </Box>
                </Stack>
                <Typography variant='body2'>{visibilite === 0 ? 'Tout le monde peut voir votre évènement. Les évènements publics sont visibles sur Choptonticket (site web & appli)' : 'Seules les personnes ayant le lien vers votre évènement pourront le voir.'}</Typography>
              </Box>
              <Box sx={{ padding: 2, borderRadius: '10px', border: '2px solid gainsboro', margin: 2 }}>
                <Typography variant="h5">Tags</Typography>
                <Typography variant="body2">Aidez les gens à découvrir votre événement en ajoutant des étiquettes associées au thème, au sujet, à l'ambiance, au lieu de votre événement, etc.</Typography>
                <Box sx={{ maxWidth: 500, marginTop: 3 }}>

                  <Autocomplete
                    multiple
                    id="tags-standard"
                    options={allTags}
                    getOptionLabel={(option) => option}
                    value={selectedTags}
                    onChange={handleTagChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Ajouter des mots clés à votre event"
                        error={!!error}
                        multiline
                        helperText={error}
                      />
                    )}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          key={option}
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                  />

                  <Typography variant='caption'>{selectedTags.length} / 10 tags</Typography>
                  {error && (
                    <Typography color="error" variant="body2" sx={{ marginTop: 1 }}>
                      {error}
                    </Typography>
                  )}
                </Box>
              </Box>



              {/* ----------------------------------------------------- */}

              <Box sx={{ p: 3 }} dir="ltr">
                <Stack spacing={1} direction='row' justifyContent='end'>
                  <CustomButton
                    type='primaire'
                    backgroundColor="black"
                    color="#fff"
                    buttonText='Planifier'
                    startIcon={<Iconify icon='ic:baseline-schedule' sx={{ width: 20, height: 20 }} />}
                    onClick={() => {
                      navigate(`/${eventID}/dashboard/app`, { replace: true });
                    }}
                  />
                  <CustomButton
                    type='primaire'
                    backgroundColor="black"
                    color="#fff"
                    buttonText='Publier'
                    onClick={handlePublication}
                  />
                </Stack>
              </Box>
            </Container>
            <Container>
              <Stack direction='row'
                spacing={2}
                sx={{
                  position: 'fixed',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  backgroundColor: 'white',
                  padding: '16px',
                  // borderTop: '2px solid gainsboro',
                  boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
                  zIndex: 1000,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <CustomButton
                  type='secondaire'
                  buttonText='Retour'
                  startIcon={<Iconify icon='ion:arrow-back-outline' sx={{ width: 20, height: 20 }} />}
                  onClick={() => {
                    navigate(`/${eventID}/dashboard/app`, { replace: true });
                  }}
                />
                <Stack spacing={1} direction='row'>
                  <CustomButton
                    type='primaire'
                    backgroundColor="black"
                    color="#fff"
                    buttonText='Planifier'
                    startIcon={<Iconify icon='ic:baseline-schedule' sx={{ width: 20, height: 20 }} />}
                    onClick={() => {
                      navigate(`/${eventID}/dashboard/app`, { replace: true });
                    }}
                  />
                  <CustomButton
                    type='primaire'
                    backgroundColor="black"
                    color="#fff"
                    buttonText='Publier'
                    onClick={handlePublication}
                  />
                </Stack>

              </Stack>
            </Container>
          </>
        ) : (
          <Container key={event.nomEvent} sx={{ marginTop: { xs: 3 } }}>
            <Stack spacing={4} paddingBottom={5}>
              <Confetti />
              <Typography variant='h2'>Vous avez publié votre event</Typography>
              <Typography variant='body1'><strong>Your event is in 34 days!</strong></Typography>
              <Grid container paddingY={2} paddingX={2} sx={{
                display: 'flex', alignItems: 'center', border: '2px solid gainsboro', borderRadius: '10px'
              }} >
                <Grid item xs={10}>
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <Stack justifyContent='center' alignItems='center' sx={{ color: '#5E17EB' }}>
                      <Typography variant='body2'><strong>{format(new Date(handleTimestamp(event.dateDebut)), 'dd')}</strong></Typography>
                      <Typography sx={{ textTransform: 'uppercase' }} variant='body2'>{format(new Date(handleTimestamp(event.dateDebut)), 'MMM', { locale: fr })}</Typography>
                    </Stack>
                    <LazyLoadImage
                      src={`${event.idVisuel}`}
                      alt={`${event.idVisuel}`}
                      className="img-lazy"
                      width={90}
                      height={90}
                      style={{ objectFit: 'cover' }}
                      effect="blur" // opacity | black-and-white
                    />
                    <Stack>
                      <Typography variant='body2'><strong>{event.nomEvent}</strong></Typography>
                      <Typography variant='caption'>{event.lieu_event}</Typography>
                      <Typography variant='caption'>{format(new Date(handleTimestamp(event.dateDebut)), 'EEEE dd, MMMM yyyy', { locale: fr })} à {format(new Date(handleTimestamp(event.dateDebut)), 'HH:mm', { locale: fr })}</Typography>
                      <Stack direction='row' spacing={1}>
                        {
                          event.seanceActive === 1 ?
                            <Box
                              padding={0.25}
                              width={90}
                              sx={{ bgcolor: '#f2f2f2', borderRadius: '10px', paddingY: 0.5, paddingX: 1, fontSize: 10 }}
                            >
                              Plusieurs dates
                            </Box>
                            : null
                        }
                        {
                          event.visibily === 0 ?
                            <Box
                              padding={0.25}
                              width={90}
                              sx={{ bgcolor: '#f2f2f2', borderRadius: '10px', paddingY: 0.5, paddingX: 1, fontSize: 10 }}
                            >
                              <Stack direction='row' spacing={1} alignItems='center'>
                                <Iconify icon='ic:outline-public' sx={{ height: 15, width: 15 }} />
                                <Typography variant='caption'>Public</Typography>
                              </Stack>
                            </Box>
                            : event.visibily === 1 ?
                              <Box
                                padding={0.25}
                                width={90}
                                sx={{ bgcolor: '#f2f2f2', borderRadius: '10px', paddingY: 0.5, paddingX: 1, fontSize: 10 }}
                              >
                                <Stack direction='row' spacing={1} alignItems='center'>
                                  <Iconify icon='mdi:lock' sx={{ height: 15, width: 15 }} />
                                  <Typography variant='caption'>Privé</Typography>
                                </Stack>
                              </Box>
                              :
                              <Box
                                padding={0.25}
                                width={90}
                                sx={{ bgcolor: '#f2f2f2', borderRadius: '10px', paddingY: 0.5, paddingX: 1, fontSize: 10 }}
                              >
                                <Stack direction='row' spacing={1} alignItems='center'>
                                  <Iconify icon='ic:outline-public' sx={{ height: 15, width: 15 }} />
                                  <Typography variant='caption'>Public</Typography>
                                </Stack>
                              </Box>

                        }

                      </Stack>
                    </Stack>
                  </Stack>
                </Grid>

                <Grid item xs={2}>
                  <CustomButton
                    fullWidth
                    backgroundColor="#5E17EB"
                    color="#fff"
                    type='primaire'
                    buttonText={<Typography>Retourner au tableau de bord</Typography>}
                    onClick={() => {
                      navigate(`/${eventID}/dashboard/app`, { replace: true });
                    }}
                  />
                </Grid>
              </Grid>
              <Box paddingTop={4}>
                <Typography variant='h4'>Voici comment vous pouvez vendre tous les billets à votre événement</Typography>
                <Typography variant='body2'>Nos outils de marketing permettent de ne pas faire la promotion de vos événements à l'aveuglette pour que vous puissiez vendre plus de billets, plus rapidement</Typography>
              </Box>
              <Stack direction='row' spacing={2} useFlexGap flexWrap="wrap" justifyContent='space-between'>
                <Card sx={{ flex: 1 }}>
                  <CardMedia
                    sx={{ height: 350 }}
                    image={ShareTikTok}
                    title="green iguana"
                  />
                  <CardContent>
                    <Stack spacing={2}>
                      <Typography variant='body1'><strong>Partagez votre événement avec vos abonnés</strong></Typography>
                      <Typography variant='body1'>En quelques clics, partagez votre événement sur TikTok et LinkedIn.</Typography>
                      <CustomButton
                        fullWidth
                        type='primaire'
                        buttonText='Partargez sur tiktok'
                        onClick={() => {
                          navigate(`/${eventID}/dashboard/app`, { replace: true });
                        }}
                      />
                    </Stack>
                  </CardContent>
                </Card>
                <Card sx={{ flex: 1 }}>
                  <CardMedia
                    sx={{ height: 350 }}
                    image={EmailMarketing}
                    title="green iguana"
                  />
                  <CardContent>
                    <Stack spacing={2}>
                      <Typography variant='body1'><strong>Envoyez une campagne par e-mail</strong></Typography>
                      <Typography variant='body1'>Engagez de publics nouveaux et actuels avec une campagne marketing par email.</Typography>
                      <CustomButton
                        fullWidth
                        type='primaire'
                        buttonText='Créer une campagne'
                        onClick={() => {
                          navigate(`/${eventID}/dashboard/app`, { replace: true });
                        }}
                      />
                    </Stack>
                  </CardContent>
                </Card>
                <Card sx={{ flex: 1 }}>
                  <CardMedia
                    sx={{ height: 350 }}
                    image={Sharefb}
                    title="green iguana"
                  />
                  <CardContent>
                    <Stack spacing={2}>
                      <Typography variant='body1'><strong>Ajouter votre événement sur Facebook</strong></Typography>
                      <Typography variant='body1'>Créez un événement Facebook directement à partir de choptonticket et regardez vos ventes de billets décoller.</Typography>
                      <CustomButton
                        fullWidth
                        type='primaire'
                        buttonText='Ajouter à facebook'
                        onClick={() => {
                          navigate(`/${eventID}/dashboard/app`, { replace: true });
                        }}
                      />
                    </Stack>
                  </CardContent>
                </Card>
              </Stack>
            </Stack>
          </Container>

        )
      )}
    </Page>
  );
}
