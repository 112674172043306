/* eslint-disable radix */
/* eslint-disable prefer-template */
/* eslint-disable no-nested-ternary */
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState } from 'react';
import { Link as RouterLink, useParams, useSearchParams } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Paper,
  TableHead,
  Grid,
  CardActions,
  CardContent,
  Box,
  Modal,
  TextField,
  LinearProgress,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Divider,
  Select,
  MenuItem,
  Skeleton,
  Pagination,
  Badge,
  Menu,
  Alert,
  CircularProgress,
  alpha,
} from '@mui/material';
// eslint-disable-next-line import/no-duplicates
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  where,
  getCountFromServer,
  orderBy,
  startAfter,
  endBefore,
  limitToLast,
  updateDoc,
} from 'firebase/firestore';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';

// eslint-disable-next-line import/no-duplicates

import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { LoadingButton } from '@mui/lab';
import { httpsCallable } from 'firebase/functions';
import { styled } from '@mui/system';
import { db, functions } from '../../firebase.config';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../sections/@dashboard/user';
// mock
import USERLIST from '../../_mock/user';
import PdfDocument from '../../components/pdf/pdfdoc';
import Swipeable from '../crm/component/swiper';
import { Variables } from '../../context/VariableContext';
import PdfTicket from '../../components/pdf/Ticket/pdfTicket';
// eslint-disable-next-line no-var
var QRCode = require('qrcode');

// ----------------------------------------------------------------------

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 500,
  // maxHeight:200,
  bgcolor: 'white',
  border: '1px solid white',
  borderRadius: '7px',
  boxShadow: 0,
  p: 3,
};

// ----------- for more bouton modal
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 5,
    marginTop: theme.spacing(1),
    minWidth: 200,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 15,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

// ----------------------------------------------------------------------

const options = ['Exporter la liste des participants', 'Importer des commandes'];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function Participants() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { type, setType } = Variables();
  const { openDrawer, setOpenDrawer } = Variables();
  const [curent, setCurent] = React.useState();
  const { eventID } = useParams();
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);

  const [selected, setSelected] = useState([]);

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    console.log('print');
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    if (page < newPage) {
      next();
    } else {
      console.log('back');
      back();
    }
    console.log(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // pagination

  const next = async () => {
    const lastVisible = billets[billets.length - 1];
    const querySnapshot1 = await getDocs(
      query(
        collection(db, `evenement/${eventID}/billets`),
        ...queryContains,
        orderBy('createDate', 'desc'),
        startAfter(lastVisible),
        limit(10)
      )
    );
    setBillet([]);
    setCommandes([]);
    setName([]);
    querySnapshot1.forEach(async (d) => {
      setBillet((billets) => [...billets, d]);
      // get commandes
      const docSnap = await getDoc(doc(db, `evenement/${eventID}/commandes`, `${d.data().refCommande}`));
      if (docSnap.exists()) {
        setCommandes((commandes) => [...commandes, docSnap.data()]);
        setName((names) => [...names, docSnap.data().nom]);
      }
    });
  };

  const back = async () => {
    const lastVisible = billets[0];
    const querySnapshot1 = await getDocs(
      query(
        collection(db, `evenement/${eventID}/billets`),
        ...queryContains,
        orderBy('createDate', 'desc'),
        endBefore(lastVisible),
        limitToLast(10)
      )
    );
    setBillet([]);
    setCommandes([]);
    setName([]);
    querySnapshot1.forEach(async (d) => {
      setBillet((billets) => [...billets, d]);
      // get commandes
      const docSnap = await getDoc(doc(db, `evenement/${eventID}/commandes`, `${d.data().refCommande}`));
      if (docSnap.exists()) {
        setCommandes((commandes) => [...commandes, docSnap.data()]);
        setName((names) => [...names, docSnap.data().nom]);
      }
    });
  };

  // take tickets from firebase

  const [billets, setBillet] = React.useState([]);
  const [commandes, setCommandes] = React.useState([]);
  const [billetsD, setBilletD] = React.useState([]);
  const [namesD, setNameD] = React.useState([]);
  const [names, setName] = React.useState([]);
  const [queryContains, setQueryContains] = React.useState([]);
  const [prenoms, setPrenom] = React.useState([]);
  const [reload, setReload] = React.useState(false);
  // ticket download
  const [info, setInfo] = React.useState();
  React.useEffect(() => {
    (async () => {
      try {
        const term = searchParams.get('q');
        console.log('params: ', term);
        const query1 = [];
        setLoading(true);
        setCommandes([]);
        setBillet([]);
        if (term) {
          //
          const tmpfilter = term.split('&');
          console.log(tmpfilter);
          tmpfilter.forEach((element) => {
            if (element.includes('fs')) {
              console.log('filter statut');
              const tmp = element.split('=');
              console.log(tmp);
              const tmp1 = tmp[1].split(',');
              console.log(tmp1);
              const filterStatut = [];
              tmp1.forEach((element) => {
                filterStatut.push(parseInt(element));
              });
              if (filterStatut.length !== 0) {
                setQueryContains((query) => [...query, where('status', 'in', filterStatut)]);
                query1.push(where('status', 'in', filterStatut));
              }
            }
            if (element.includes('fo')) {
              console.log('filter origine');
            }
            if (element.includes('fb')) {
              console.log('filter billet');
              const tmp = element.split('=');
              console.log(tmp);
              const filterBillet = tmp[1].split(',');
              console.log(filterBillet);
              if (filterBillet.length !== 0) {
                setQueryContains((query) => [...query, where('refTicket', 'in', filterBillet)]);
                query1.push(where('refTicket', 'in', filterBillet));
              }
            }
            if (element.includes('fa')) {
              const today = new Date();
              const date = new Date(today.getFullYear(), today.getMonth(), today.getDate());
              const ttmp = new Date(today.getTime() + 24 * 60 * 60 * 1000);
              const tomorrow = new Date(ttmp.getFullYear(), ttmp.getMonth(), ttmp.getDate());
              console.log('filter date ajout');
              const tmp = element.split('=');
              console.log(tmp);
              const filterHist = tmp[1];
              console.log(filterHist);
              if (filterHist === 'today') {
                console.log('add filtre ajout');
                query1.push(where('createDate', '>=', date));
                query1.push(where('createDate', '<', new Date(today.getTime() + 24 * 60 * 60 * 1000)));
                setQueryContains((query) => [...query, where('createDate', '>=', date)]);
                setQueryContains((query) => [
                  ...query,
                  where('createDate', '<', new Date(today.getTime() + 24 * 60 * 60 * 1000)),
                ]);
              } else if (filterHist === 'yesterday') {
                query1.push(where('createDate', '<=', date));
                query1.push(where('createDate', '>=', new Date(date.getTime() - 24 * 60 * 60 * 1000)));
                setQueryContains((query) => [...query, where('createDate', '<=', date)]);
                setQueryContains((query) => [
                  ...query,
                  where('createDate', '>=', new Date(date.getTime() - 24 * 60 * 60 * 1000)),
                ]);
              } else if (filterHist === 'last-7-days') {
                query1.push(where('createDate', '<=', date));
                query1.push(where('createDate', '>=', new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000)));
                setQueryContains((query) => [...query, where('createDate', '<=', date)]);
                setQueryContains((query) => [
                  ...query,
                  where('createDate', '>=', new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000)),
                ]);
              } else if (filterHist === 'last-14-days') {
                query1.push(where('createDate', '<=', date));
                query1.push(where('createDate', '>=', new Date(date.getTime() - 14 * 24 * 60 * 60 * 1000)));
                setQueryContains((query) => [...query, where('createDate', '<=', date)]);
                setQueryContains((query) => [
                  ...query,
                  where('createDate', '>=', new Date(date.getTime() - 14 * 24 * 60 * 60 * 1000)),
                ]);
              } else if (filterHist === 'last-30-days') {
                query1.push(where('createDate', '<=', date));
                query1.push(where('createDate', '>=', new Date(date.getTime() - 30 * 24 * 60 * 60 * 1000)));
                setQueryContains((query) => [...query, where('createDate', '<=', date)]);
                setQueryContains((query) => [
                  ...query,
                  where('createDate', '>=', new Date(date.getTime() - 30 * 24 * 60 * 60 * 1000)),
                ]);
              }
            }
          });
        } else {
          //
        }
        // count
        const snapshot = await getCountFromServer(query(collection(db, `evenement/${eventID}/billets`), ...query1));
        setCount(snapshot.data().count);
        // get participants
        const first = query(
          collection(db, `evenement/${eventID}/billets`),
          orderBy('createDate', 'desc'),
          ...query1,
          limit(10)
        );
        const querySnapshot1 = await getDocs(first);
        querySnapshot1.forEach(async (d) => {
          setBillet((billets) => [...billets, d]);
          // get commandes
          const docSnap = await getDoc(doc(db, `evenement/${eventID}/commandes`, `${d.data().refCommande}`));
          if (docSnap.exists()) {
            setCommandes((commandes) => [...commandes, docSnap.data()]);
            setName((names) => [...names, docSnap.data().nom]);
          }
        });
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
      setLoading(false);
    })();
  }, [reload, searchParams]);

  // loading
  const [loading, setLoading] = React.useState(false);

  // handle modification
  const [modif, setModif] = React.useState(false);

  const [curIndex, setIndex] = React.useState();

  const [statusBillet, setStatus] = React.useState('');
  const [origine, setOrigine] = React.useState();

  const handleModif = (index) => {
    if (modif) {
      setModif(false);
    } else {
      setIndex(index);
      console.log(curIndex);
      setModif(true);
    }
  };
  // convert timestamp

  const handleTimestamp = (e) => {
    const date = e.toDate();
    const tmp = date.toJSON().split('.');
    return tmp[0];
  };
  const [word, setWord] = React.useState('');
  const [isRech, setRech] = React.useState(false);
  const [isFiltre, setFiltre] = React.useState(false);
  const [filtre, openFiltre] = React.useState(false);
  const handleRecherche = async (e) => {
    setWord(e);
    console.log(e);
    try {
      if (e.length > 3) {
        console.log(e);
        setLoading(true);
        setCommandes([]);
        setBillet([]);

        const regexT = /(T\d+(\.\d)*)/;
        const regexC = /(C\d+(\.\d)*)/;

        if (e.match(regexT) !== null) {
          console.log('this is a ticket');
          /* const q1 = query(
            collection(db, "evenement"),
            where("status", "==", 1),
            where(
              "dateDebut",
              ">=",
              new Date(today.getFullYear(), today.getMonth(), today.getDate())
            ),
            orderBy("dateDebut", "asc"),
          ); 
          const querySnapshot = await getDocs(q); */
          // get participants
          const snapshot = await getCountFromServer(
            query(
              collection(db, `evenement/${eventID}/billets`),
              where('numero', '>=', e),
              where('numero', '<=', e + '~'),
              limit(10)
            )
          );
          setCount(snapshot.data().count);
          const querySnapshot1 = await getDocs(
            query(
              collection(db, `evenement/${eventID}/billets`),
              where('numero', '>=', e),
              where('numero', '<=', e + '~'),
              limit(10)
            )
          );
          querySnapshot1.forEach(async (d) => {
            console.log(d.data());
            setBillet((billets) => [...billets, d]);
            // get commandes
            const docSnap = await getDoc(doc(db, `evenement/${eventID}/commandes`, `${d.data().refCommande}`));
            if (docSnap.exists()) {
              setCommandes((commandes) => [...commandes, docSnap.data()]);
            }
          });
        } else if (e.match(regexC) !== null) {
          console.log('this is a Command');
          const snapshot = await getCountFromServer(
            query(
              collection(db, `evenement/${eventID}/billets`),
              where('refCommande', '>=', e),
              where('refCommande', '<=', e + '~'),
              limit(10)
            )
          );
          setCount(snapshot.data().count);
          const querySnapshot1 = await getDocs(
            query(
              collection(db, `evenement/${eventID}/billets`),
              where('refCommande', '>=', e),
              where('refCommande', '<=', e + '~'),
              limit(10)
            )
          );
          querySnapshot1.forEach(async (d) => {
            console.log(d.data());
            setBillet((billets) => [...billets, d]);
            // get commandes
            const docSnap = await getDoc(doc(db, `evenement/${eventID}/commandes`, `${d.data().refCommande}`));
            if (docSnap.exists()) {
              setCommandes((commandes) => [...commandes, docSnap.data()]);
            }
          });
        }
        setLoading(false);
        setRech(true);
      }
      if (e.length === 0) {
        setReload(!reload);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleClear = () => {
    setWord('');
    setRech(false);
    setReload(!reload);
  };

  const handleReinitialiser = () => {
    setFiltre(false);
    handleClear();
  };

  const handleFilter = () => {
    console.log('ouvrir le filtre');
    console.log(openDrawer);
    setType(4);
    setOpenDrawer(!openDrawer);
  };

  const handleImport = () => {
    handleCloseMenu();
    console.log('');
    console.log(openDrawer);
    setType(5);
    setOpenDrawer(!openDrawer);
  };

  // filtre
  const [filters, setFilters] = useState([]);

  // more acceuil participant
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // more acceuil participant
  const [anchorElDetail, setAnchorElDetail] = React.useState(null);
  const openMenuDetail = Boolean(anchorElDetail);
  const handleClickMenuDetail = (event) => {
    setAnchorElDetail(event.currentTarget);
  };
  const handleCloseMenuDetail = () => {
    setAnchorElDetail(null);
  };

  // Modal download liste participants
  const [openDownloadModal, setOpenDownloadModal] = React.useState(false);
  const handleOpenDownloadModal = () => setOpenDownloadModal(true);
  const handleCloseDownloadModal = () => setOpenDownloadModal(false);

  const downloadList = async () => {
    handleCloseMenu();
    handleOpenDownloadModal();
    try {
      // get participants
      const first = query(collection(db, `evenement/${eventID}/billets`), orderBy('createDate', 'desc'));
      const querySnapshot1 = await getDocs(first);
      querySnapshot1.forEach(async (d) => {
        setBilletD((billets) => [...billets, d]);
        // get commandes
        const docSnap = await getDoc(doc(db, `evenement/${eventID}/commandes`, `${d.data().refCommande}`));
        if (docSnap.exists()) {
          setNameD((names) => [...names, docSnap.data().nom]);
        }
      });
    } catch (error) {
      console.log(error);
    }
    // handleCloseDownloadModal();
  };

  // Modal download Ticket
  const [openTicketModal, setOpenTicketModal] = React.useState(false);
  const handleOpenTicketModal = () => setOpenTicketModal(true);
  const handleCloseTicketModal = () => setOpenTicketModal(false);
  const [qrcode, setQr] = useState('');

  const downloadTicket = async () => {
    try {
      const docSnap = await getDoc(doc(db, `evenement`, `${eventID}`));
      if (docSnap.exists()) {
        console.log(docSnap.data());
        console.log(billets[curIndex]);
        const orgSnap = await getDoc(doc(db, `organisations`, `${docSnap.data().orgId}`));
        console.log(orgSnap.data());
        const tmp = {
          billet: billets[curIndex].data(),
          event: docSnap.data(),
          commande: commandes[curIndex],
          org: orgSnap.data(),
        };
        setInfo(tmp);
      }

      QRCode.toDataURL(
        billets[curIndex].data().numero,
        {
          width: 130,
          margin: 1,
          errorCorrectionLevel: 'H',
          color: {
            dark: '#000000',
            light: '#ffffff',
          },
        },
        (err, url) => {
          if (err) return console.log('erreur : ', err);
          setQr(url);
        }
      );
    } catch (error) {
      console.log(error);
    }
    handleCloseMenuDetail();
    handleOpenDownloadModal();
    // handleCloseDownloadModal();
  };
  const downloadSelectedTicket = async (index) => {
    try {
      const docSnap = await getDoc(doc(db, `evenement`, `${eventID}`));
      if (docSnap.exists()) {
        console.log(docSnap.data());
        console.log(billets[index]);
        const orgSnap = await getDoc(doc(db, `organisations`, `${docSnap.data().orgId}`));
        console.log(orgSnap.data());
        const tmp = {
          billet: billets[index].data(),
          event: docSnap.data(),
          commande: commandes[index],
          org: orgSnap.data(),
        };
        setInfo(tmp);
      }

      QRCode.toDataURL(
        billets[index].data().numero,
        {
          width: 130,
          margin: 1,
          errorCorrectionLevel: 'H',
          color: {
            dark: '#000000',
            light: '#ffffff',
          },
        },
        (err, url) => {
          if (err) return console.log('erreur : ', err);
          setQr(url);
        }
      );
    } catch (error) {
      console.log(error);
    }
    handleCloseMenuDetail();
    handleOpenTicketModal();
    // handleCloseDownloadModal();
  };

  // Modal Remboursement
  const [openRembModal, setOpenRembModal] = React.useState(false);
  const [loadingRemb, setLoadingRemb] = React.useState(false);
  const handleOpenRembModal = () => setOpenRembModal(true);
  const handleCloseRembModal = () => setOpenRembModal(false);

  const handleRenboursement = async () => {
    handleCloseMenuDetail();
    handleOpenRembModal();
  };
  const handleRenboursementPin = async () => {
    setLoadingRemb(true);
    handleCloseMenuDetail();
    handleOpenRembModal();
    try {
      // envoye du token de validation
      const addMessage = httpsCallable(functions, 'email_token');
      const result = await addMessage({ email: commandes[curIndex]?.email, event: eventID });
    } catch (error) {
      //
    }
    setLoadingRemb(false);
    handleOpenRembModal();
    handleCloseRembModal();
    // ouverture du modal de validation du token envoyé par mail
    handleOpenTokenModal();
  };
  const handleRenboursementConfirm = async () => {
    setLoadingRemb(true);
    handleCloseMenuDetail();
    handleOpenRembModal();
    try {
      // envoye du token de validation
      const addMessage = httpsCallable(functions, 'email_token');
      const result = await addMessage({ email: commandes[curIndex]?.email, event: eventID });
    } catch (error) {
      //
    }
    setLoadingRemb(false);
    handleOpenRembModal();
    handleCloseRembModal();
    // ouverture du modal de validation du token envoyé par mail
    handleOpenTokenModal();
  };

  // Modal token validation
  const [pin, setPin] = React.useState(['', '', '', '', '', '', '']);
  const handlePinChange = (index, value) => {
    const newPin = [...pin];
    if (/^\d$/.test(value)) {
      newPin[index] = value;
    }

    if (newPin[index] !== undefined && newPin[index] !== null) {
      newPin[index] = value.charAt(value.length - 1);
    }
    if (index > 0 && value === '') {
      // If a digit is deleted, move focus to the previous input
      document.getElementById(`pin-input-${index - 1}`).focus();
    } else if (index < 6 && value !== '') {
      // Move focus to the next input
      document.getElementById(`pin-input-${index + 1}`).focus();
    }
    setPin(newPin);
    // Check if all 7 digits are entered
    if (newPin.every((digit) => digit !== '')) {
      setToken(newPin.join(''));
      verifiedToken(newPin.join(''));
    }
  };

  //------------------------------------------------------
  const [openTokenModal, setOpenTokenModal] = React.useState(false);
  const [token, setToken] = React.useState('');
  const [percent, setPercent] = React.useState();
  const [billetRembourser, setBilletRembourser] = React.useState();
  const [montantRembourser, setMontantRembourser] = React.useState();
  const [tokenError, setTokenError] = React.useState(false);
  const handleOpenTokenModal = () => setOpenTokenModal(true);
  const handleCloseTokenModal = () => setOpenTokenModal(false);

  const handleChangePourcentage = (e) => {
    console.log(e.target.value)
    if (e.target.value !== '' && e.target.value > 0) {
      setPercent(e.target.value)
    }
  };

  const verifiedToken = async (e) => {
    setLoadingRemb(true);
    try {
      const docSnap = await getDoc(doc(db, `actionToken`, `${eventID}-${e}`));
      if (docSnap.exists()) {
        if (docSnap.data().actif) {
          await updateDoc(doc(db, `actionToken`, `${eventID}-${e}`), {
            actif: false,
          });
          // await getDoc(doc(db, `Remboursements`, `${docSnap.data().orgId}`));
          // Lancer le processus de Remboursement
          setTokenError(false);
          handleCloseTokenModal();
          handleOpenRembModal();
        } else {
          setTokenError(true);
          setPin(['', '', '', '', '', '', '']);
        }
      } else {
        setTokenError(true);
        setPin(['', '', '', '', '', '', '']);
      }
    } catch (error) {
      console.error(error);
    }
    setToken('');
    setLoadingRemb(false);
    // handleCloseMenuDetail();

    // handleCloseDownloadModal();
  };

  const makeRenboursement = async () => {
    setLoadingRemb(true);
    try {
      const docSnap = await getDoc(doc(db, `actionToken`, `${eventID}-${token}`));
      if (docSnap.exists()) {
        if (docSnap.data().actif) {
          await updateDoc(doc(db, `actionToken`, `${eventID}-${token}`), {
            actif: false,
          });
          // await getDoc(doc(db, `Remboursements`, `${docSnap.data().orgId}`));
        }
      }
    } catch (error) {
      console.log(error);
    }
    setToken('');
    handleCloseMenuDetail();
    handleCloseTokenModal();
    // handleCloseDownloadModal();
  };

  return (
    <Page title="Gestion Participants">
      {loading && <LinearProgress />}
      {!modif ? (
        <Container sx={{ marginTop: { xs: 3 } }}>
          <Grid container spacing={2}>
            <Grid item xs={10} sm={10} md={10}>
              <Typography variant="h3" gutterBottom>
                Gérer les participants ({count}) <br />
                <Typography variant="body2">Trouver et administrer les commandes</Typography>
              </Typography>
            </Grid>
            <Grid sx={{ display: 'flex', justifyContent: 'end', alignItems: 'end', bg: 'yellow' }} item xs={2}>
              <Box>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={openMenu ? 'long-menu' : undefined}
                  aria-expanded={openMenu ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={handleClickMenu}
                  sx={{ border: '1px solid gainsboro' }}
                >
                  <Iconify icon="material-symbols-light:more-vert" />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
          <StyledMenu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleCloseMenu}
          >

            <MenuItem onClick={handleImport} disableRipple value={0}>
              <Typography variant='body1'>Importer des commandes</Typography>
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                handleCloseMenu();
                // handleOpenDeleteModal()
                downloadList()
              }}
              disableRipple
              value={1}
            >
              <Typography variant='body1'>Télécharger la liste des participants</Typography>
            </MenuItem>

          </StyledMenu>

          <Modal
            open={openDownloadModal}
            onClose={handleCloseDownloadModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ borderRadius: '7px' }}
          >
            <Box sx={styleModal}>
              <Typography variant="body2">
                Cliquez{' '}
                <PDFDownloadLink
                  document={<PdfDocument billetList={billetsD} commandeList={namesD} />}
                  fileName={'liste_participants.pdf'}
                >
                  {({ blob, url, loading, error }) => {
                    console.log('erreur : ', error);
                    return loading ? 'Genration du ticket......' : 'ici';
                  }}
                </PDFDownloadLink>{' '}
                pour Telecharger la liste des participants
              </Typography>
            </Box>
          </Modal>
          <Modal
            open={openTicketModal}
            onClose={handleCloseTicketModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ borderRadius: '7px' }}
          >
            <Box sx={styleModal}>
              <Typography variant="body2">
                Cliquez{' '}
                <PDFDownloadLink
                  document={<PdfTicket info={info} qr={qrcode} />}
                  fileName={`${info ? info.billet.refCommande : 'undefined'}.pdf`}
                >
                  {({ blob, url, loading, error }) => {
                    console.log('erreur : ', error);
                    return loading ? 'Genration du ticket......' : 'ici';
                  }}
                </PDFDownloadLink>{' '}
                pour techarger le ticket
              </Typography>
            </Box>
          </Modal>
          <Stack
            direction='row'
            justifyContent={{ xs: 'start', sm: 'end' }}
            alignItems='center'
            spacing={2}
            sx={{ width: '100%' }}
            mt={3}
            paddingBottom={2}
            useFlexGap flexWrap="wrap"
          >
            <TextField
              value={word}
              onChange={(e) => handleRecherche(e.target.value)}
              // size="small"
              placeholder="Rechercher"
              id="outlined-start-adornment"
              sx={{ m: 1, width: '40ch' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="iconoir:search" />
                  </InputAdornment>
                ),
              }}
            />
            {queryContains.length > 0 ? (
              <Badge badgeContent=" " color="primary">
                <Button
                  sx={{
                    textTransform: 'initial',
                    border: '2px solid transparent',
                    color: 'white',
                    backgroundColor: '#5E17EB',
                    borderRadius: '7px',
                    boxShadow: 'none',
                    '&:hover': {
                      backgroundColor: 'white',
                      color: '#5E17EB',
                      borderColor: '#5E17EB',
                    },
                  }}
                  variant="contained"
                  startIcon={<Iconify icon="circum:filter" />}
                  endIcon={<Iconify onClick={() => {
                    console.log('hello comment')
                  }} icon="circum:filter" />}
                  onClick={handleFilter}
                >
                  Filtres {queryContains.length}
                </Button>
              </Badge>
            ) : (
              <Button
                sx={{
                  textTransform: 'initial',
                  border: '2px solid transparent',
                  color: 'white',
                  backgroundColor: '#5E17EB',
                  borderRadius: '7px',
                  boxShadow: 'none',
                  '&:hover': {
                    backgroundColor: 'white',
                    color: '#5E17EB',
                    borderColor: '#5E17EB',
                  },
                }}
                variant="contained"
                startIcon={<Iconify icon="circum:filter" />}
                onClick={handleFilter}
              >
                Filtres
              </Button>
            )}


          </Stack>
          {
            openDrawer && <Swipeable type={4} user={curent} />
          }

          <Box>
            {/** filtrer */}
            <Divider />
            <Scrollbar>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="caption table">
                  <TableHead>
                    <TableRow>
                      
                      <TableCell align="left">Billet</TableCell>
                      <TableCell align="left">Acheteur</TableCell>
                      <TableCell align="left">Tags</TableCell>
                    </TableRow>
                  </TableHead>
                  {loading ? (
                    <caption>
                      <Skeleton variant="rounded" width="100%" />
                    </caption>
                  ) : billets.length === 0 && !loading ? (
                    <caption>
                      <Stack spacing={1} sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        <Iconify icon="icomoon-free:drawer2" sx={{ width: 30, height: 30 }} />
                        <Typography variant="caption">Aucune donnée</Typography>
                      </Stack>
                    </caption>
                  ) : (
                    <>
                      {
                        <TableBody>
                          {commandes.length === billets.length &&
                            billets.map((billet, index) => (
                              <TableRow key={index} sx={{ cursor: 'pointer' }} hover>
                                
                                <TableCell align="left" onClick={() => handleModif(index)}>
                                  <Stack>
                                    <Typography variant='subtitle2'>{billet.data().numero}</Typography>
                                    <Typography variant="caption">N° Commande : <strong>{billet.data().refCommande}</strong></Typography>
                                    <Typography variant='caption'> Acheté le {format(new Date(handleTimestamp(commandes[index].date)), 'EEEE dd, MMMM yyyy', { locale: fr })} à {format(new Date(handleTimestamp(commandes[index].date)), 'HH:mm', { locale: fr })}</Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell align="left" onClick={() => handleModif(index)}>
                                  <Stack>
                                    <Typography variant='subtitle2'>

                                      {commandes[index].nom === '' ? (
                                        <div>Inconnu</div>
                                      ) : (
                                        <div>{commandes[index].nom}</div>
                                      )}


                                    </Typography>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                      <Iconify icon="ion:mail-outline" />
                                      <Typography variant="caption">{commandes[index].email}</Typography>
                                    </Stack>
                                  </Stack>
                                </TableCell>

                                <TableCell align="center" onClick={() => handleModif(index)}>
                                  <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                                    {billet.data().scanner === 1 && (
                                      <Box
                                        component="button"
                                        padding={0.5}
                                        sx={{
                                          borderRadius: '5px',
                                          fontSize: 10,
                                          backgroundColor: '#F5EFFA',
                                          border: '1px solid #B991DE',
                                        }}
                                      >
                                        Scanné
                                      </Box>
                                    )}
                                    {billet.data().status === 2 && (
                                      <Box
                                        component="button"
                                        padding={0.5}
                                        sx={{
                                          borderRadius: '5px',
                                          fontSize: 10,
                                          backgroundColor: '#F5EFFA',
                                          border: '1px solid #B991DE',
                                        }}
                                      >
                                        Validé
                                      </Box>
                                    )}
                                    {commandes[index].status === 1 && (
                                      <Box
                                        component="button"
                                        padding={0.5}
                                        sx={{
                                          borderRadius: '5px',
                                          fontSize: 10,
                                          backgroundColor: '#F5EFFA',
                                          border: '1px solid #B991DE',
                                        }}
                                      >
                                        payé
                                      </Box>
                                    )}
                                    {commandes[index].status === 0 && (
                                      <Box
                                        component="button"
                                        padding={0.5}
                                        sx={{
                                          borderRadius: '5px',
                                          fontSize: 10,
                                          backgroundColor: '#F5EFFA',
                                          border: '1px solid #B991DE',
                                        }}
                                      >
                                        Reservé
                                      </Box>
                                    )}
                                  </Stack>
                                </TableCell>
                                <TableCell align="right">
                                  <IconButton key={index} onClick={(e) => downloadSelectedTicket(index)}>
                                    <Iconify icon="material-symbols-light:download" />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      }
                    </>
                  )}
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Scrollbar>
          </Box>
        </Container>
      ) : (
        <Container sx={{ marginTop: { xs: 3 } }}>
          {commandes.length !== 0 && billets.length !== 0 && (
            <>
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                <Button
                  variant="contained"
                  sx={{
                    textTransform: 'initial',
                    border: '2px solid transparent',
                    color: 'black',
                    backgroundColor: 'white',
                    borderRadius: '7px',
                    borderColor: 'black',
                    boxShadow: 'none',
                    '&:hover': {
                      backgroundColor: 'black',
                      color: 'white',
                      borderColor: 'black',
                    },
                  }}
                  startIcon={<Iconify icon="material-symbols:arrow-back-ios" />}
                  onClick={() => setModif(false)}
                >
                  retour
                </Button>
              </Box>

              <Stack direction="row" alignItems="center" justifyContent="space-between" marginY={2}>
                {commandes[curIndex].nom === '' ? (
                  <Typography variant="h6" gutterBottom>
                    Billet nom nominatif
                  </Typography>
                ) : (
                  <Typography variant="h6" gutterBottom>
                    {commandes[curIndex].nom}
                  </Typography>
                )}

                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={openMenuDetail ? 'long-menu' : undefined}
                  aria-expanded={openMenuDetail ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={handleClickMenuDetail}
                  sx={{ bgcolor: '#f2f2f2' }}
                >
                  <Iconify icon="mi:options-horizontal" />
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    'aria-labelledby': 'long-button',
                  }}
                  anchorEl={anchorElDetail}
                  open={openMenuDetail}
                  onClose={handleCloseMenuDetail}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: '30ch',
                    },
                  }}
                >
                  <MenuItem key="1" onClick={() => downloadTicket()}>
                    <Iconify icon="material-symbols-light:download" />
                    Telecharger
                  </MenuItem>
                  <MenuItem key="1" onClick={() => handleRenboursement()}>
                    <Iconify icon="heroicons:receipt-refund" />
                    Rembourser
                  </MenuItem>
                </Menu>
                <Modal
                  open={openDownloadModal}
                  onClose={handleCloseDownloadModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  sx={{ borderRadius: '7px' }}
                >
                  <Box sx={styleModal}>
                    <Typography variant="body2">
                      Cliquez{' '}
                      <PDFDownloadLink
                        document={<PdfTicket info={info} qr={qrcode} />}
                        fileName={`${info ? info.billet.refCommande : 'undefined'}.pdf`}
                      >
                        {({ blob, url, loading, error }) => {
                          console.log('erreur : ', error);
                          return loading ? 'Genration du ticket......' : 'ici';
                        }}
                      </PDFDownloadLink>{' '}
                      pour techarger le ticket
                    </Typography>
                  </Box>
                </Modal>
                {/** Modal Remboursement */}
                <Modal
                  open={openRembModal}
                  onClose={handleCloseRembModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  sx={{ borderRadius: '7px' }}
                >
                  <Box sx={styleModal}>
                    {loadingRemb ? (
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress />
                      </Box>
                    ) : (
                      <>
                        <Stack direction="row">
                          <Typography variant="h6">Remboursement</Typography>
                        </Stack>

                        <Box sx={{ p: 2 }} dir="ltr">
                          {commandes[curIndex].totalBillet > 1 && (
                            <Alert severity="info">
                              Ce ticket fait partir de la meme commandes avec {commandes[curIndex].totalBillet - 1} autre(s) ticket(s).
                            </Alert>
                          )}
                        </Box>
                        <Box sx={{ p: 2 }} dir="ltr">
                          <TextField
                            label="Pourcentage à rembouser"
                            required
                            fullWidth
                            helperText="100% du prix du billet dera remboursé."
                            type="number"
                            value={percent}
                            onChange={handleChangePourcentage}
                          />
                        </Box>

                        <Box
                          sx={{
                            height: '100%',
                            borderRadius: '7px',
                            padding: 2,
                            border: '2px solid transparent',
                            bgcolor: '#f2f2f2',
                            borderColor: '#f2f2f2',
                          }}
                        >
                          <Typography>Recapitulatif</Typography>
                          <Typography variant="caption">Nombre de billets à rembourser :</Typography>
                          <Typography variant="body2">1</Typography>
                          <Typography variant="caption">Montant total à rembourser :</Typography>
                          <Typography variant="body2">1000 CFA</Typography>
                        </Box>

                        <Grid container spacing={2} justifyContent="end" paddingTop={5}>
                          <Grid item>
                            <Button
                              variant="outlined"
                              sx={{
                                textTransform: 'initial',
                                border: '2px solid transparent',
                                color: 'black',
                                backgroundColor: 'white',
                                borderRadius: '7px',
                                borderColor: 'black',
                                '&:hover': {
                                  backgroundColor: 'black',
                                  color: 'white',
                                  borderColor: 'black',
                                },
                              }}
                              onClick={handleCloseRembModal}
                            >
                              Annuler
                            </Button>
                          </Grid>
                          <Grid item>
                            <LoadingButton
                              variant="contained"
                              sx={{
                                textTransform: 'initial',
                                border: '2px solid transparent',
                                Color: 'white',
                                backgroundColor: 'red',
                                borderRadius: '7px',
                                '&:hover': {
                                  backgroundColor: 'white',
                                  color: 'red',
                                  borderColor: 'red',
                                },
                              }}
                              onClick={handleRenboursementPin}
                            >
                              Confirmer le remboursement
                            </LoadingButton>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Box>
                </Modal>
                {/** Fin Modal remboursement */}
                {/** Modal validation de token */}
                <Modal
                  open={openTokenModal}
                  onClose={handleCloseTokenModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  sx={{ borderRadius: '7px' }}
                >
                  <Box sx={styleModal}>
                    <Typography variant="h6">Token de validation</Typography>
                    <Box sx={{ p: 2 }} dir="ltr">
                      <Typography variant="body2">
                        Veuillez entrez le code de validation qui vous a été envoyé par mail.
                      </Typography>
                    </Box>
                    {tokenError && !loadingRemb && (
                      <Box sx={{ p: 2 }} dir="ltr">
                        <Alert severity="error">Le code saisi est incorrecte, veuillez saisir le bon code.</Alert>
                      </Box>
                    )}
                    <Box sx={{ p: 2 }} dir="ltr">
                      {loadingRemb ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <CircularProgress />
                        </Box>
                      ) : (
                        <Stack direction="row" spacing={2} justifyContent="center">
                          {pin.map((digit, index) => (
                            <Box key={index}>
                              <TextField
                                key={index}
                                id={`pin-input-${index}`}
                                type="number"
                                variant="outlined"
                                size="medium"
                                maxLength="1"
                                value={digit}
                                onChange={(e) => handlePinChange(index, e.target.value)}
                                sx={{ width: '40px' }}
                              />
                            </Box>
                          ))}
                        </Stack>
                      )}
                    </Box>
                  </Box>
                </Modal>
                {/** Fin Modal validation de token */}
              </Stack>
              <Grid container justifyContent="space-between" spacing={5}>
                <Grid item xs={12} sm={6}>
                  <Box
                    sx={{
                      height: '100%',
                      borderRadius: '7px',
                      padding: 3,
                      border: '2px solid transparent',
                      cursor: 'pointer',
                      bgcolor: '#f2f2f2',
                    }}
                  >
                    <Iconify icon="ph:ticket-thin" sx={{ width: 25, height: 25 }} />
                    <Typography textAlign="Start">
                      <strong>Billet N° {billets[curIndex].data().numero}</strong>
                    </Typography>
                    <Stack spacing={2} paddingTop={2}>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2">
                          <strong>Nom du ticket :</strong>
                        </Typography>
                        <Typography variant="body2">{billets[curIndex].data().nom}</Typography>
                      </Stack>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2">
                          <strong>Prix :</strong>
                        </Typography>
                        <Typography variant="body2">
                          {billets[curIndex].data().price === 0 ? 'Gratuit' : billets[curIndex].data().price + ' CFA'}{' '}
                        </Typography>
                      </Stack>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2">
                          <strong>Scanné :</strong>
                        </Typography>
                        <Typography variant="body2">
                          {billets[curIndex].data().scanner === 1 ? 'oui' : 'non'}{' '}
                        </Typography>
                      </Stack>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2">
                          <strong>Status :</strong>
                        </Typography>
                        <Typography variant="body2">
                          {billets[curIndex].data().status === 1 ? 'actif' : 'desactivé'}{' '}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box
                    sx={{
                      height: '100%',
                      borderRadius: '7px',
                      padding: 3,
                      border: '2px solid transparent',
                      cursor: 'pointer',
                      bgcolor: '#f2f2f2',
                    }}
                  >
                    <Iconify icon="material-symbols-light:order-approve-outline" sx={{ width: 25, height: 25 }} />
                    <Typography textAlign="Start">
                      <strong>Commande N° {billets[curIndex].data().refCommande}</strong>
                    </Typography>
                    <Stack spacing={2} paddingTop={2}>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2">
                          <strong>Nombre de billets :</strong>
                        </Typography>
                        <Typography variant="body2">{commandes[curIndex].totalBillet}</Typography>
                      </Stack>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2">
                          <strong>Date de la commande :</strong>
                        </Typography>
                        <Typography variant="body2">
                          {format(new Date(handleTimestamp(commandes[curIndex].date)), 'EEE dd MMM yyyy HH:mm', {
                            locale: fr,
                          }).toString()}
                        </Typography>
                      </Stack>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2">
                          <strong>Montant payé :</strong>
                        </Typography>
                        <Typography variant="body2"> {commandes[curIndex].totalPrice} CFA</Typography>
                      </Stack>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2">
                          <strong>Reduction :</strong>
                        </Typography>
                        <Typography variant="body2">
                          {billets[curIndex].data().reduction ? billets[curIndex].data().reduction : '-'}{' '}
                        </Typography>
                      </Stack>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2">
                          <strong>Moyen de paiement :</strong>
                        </Typography>
                        <Typography variant="body2">
                          {billets[curIndex].data().scanner === 1 ? 'oui' : 'non'}{' '}
                        </Typography>
                      </Stack>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2">
                          <strong>Origine :</strong>
                        </Typography>
                        <Typography variant="body2">
                          {commandes[curIndex].origine === 0
                            ? 'Ajout manuel'
                            : commandes[curIndex].origine === 1
                              ? 'Web / app'
                              : commandes[curIndex].origine === 2
                                ? 'Guichet'
                                : null}
                        </Typography>
                      </Stack>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2">
                          <strong>Statut :</strong>
                        </Typography>
                        <Typography variant="body2">
                          {' '}
                          {commandes[curIndex].status === 0
                            ? 'Reservé'
                            : commandes[curIndex].status === 1
                              ? 'Payé'
                              : null}
                        </Typography>
                      </Stack>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2">
                          <strong>N° facture :</strong>
                        </Typography>
                        <Typography variant="body2">
                          {commandes[curIndex].idFacture}{' '}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box
                    sx={{
                      height: '100%',
                      borderRadius: '7px',
                      padding: 3,
                      border: '2px solid transparent',
                      cursor: 'pointer',
                      bgcolor: '#f2f2f2',
                    }}
                  >
                    <Iconify icon="bi:person" sx={{ width: 25, height: 25 }} />
                    <Typography textAlign="Start">
                      <strong>Information sur l'acheteur</strong>
                    </Typography>
                    <Stack spacing={2} paddingTop={2}>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2">
                          <strong>Nom / prenoms :</strong>
                        </Typography>
                        <Typography variant="body2">{commandes[curIndex].nom}</Typography>
                      </Stack>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2">
                          <strong>Tel :</strong>
                        </Typography>
                        <Typography variant="body2">
                          {commandes[curIndex].tel !== '' ? commandes[curIndex].tel : '-'}{' '}
                        </Typography>
                      </Stack>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2">
                          <strong>Email :</strong>
                        </Typography>
                        <Typography variant="body2">{commandes[curIndex].email}</Typography>
                      </Stack>
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
        </Container>
      )}
    </Page>
  );
}
