import React, { createContext, useContext, useState, useEffect } from 'react';
import { setDoc, doc, collection, Timestamp, serverTimestamp, getDoc } from 'firebase/firestore';
import {createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged, sendPasswordResetEmail, confirmPasswordReset} from 'firebase/auth'
import { auth, db } from '../firebase.config';



const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
    
    const [user, setUser] = useState({})
    const [userData, setUserData] = useState()
    const [event,setCurrentEvent] = useState({})
    

    function signUp(email, password){
        return createUserWithEmailAndPassword(auth, email, password)
    }

    

    function signUpSecondStep(id,mail, idorg){
        
        const newCityRef = doc(db, "organisateurs", id);
        setDoc(newCityRef, {
            id,
            role: {id:0, name: 'super Admin'},
            dateCreation: serverTimestamp(),
            email: mail,
            idOrg: idorg,
        });
    }

    function logIn(email, password){
        return signInWithEmailAndPassword(auth, email, password)
    }

    

    function logOut(){
        setUserData()
        return signOut(auth)
    }

    function addEvent(name, dateDebut, dateFin, location, idVisuel){

    }

    function resetpass(oobCode, newpass){
        return confirmPasswordReset(auth, oobCode, newpass)
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) =>{
            console.log(currentUser)
            setUser(currentUser)
            
        })
        return () => {
            unsubscribe();
        }
    })

    useEffect(() => {
        (async () => {
          if (user != null) {
            try {
                const docRef0 = doc(db, 'users', user?.uid);
                const docSnap = await getDoc(docRef0);
                if (docSnap.exists()) {
                  setUserData(docSnap.data())
                }else {
                    setUserData(null)
                }
              } catch (error) {
                console.log(error);
              }
          } else {
            setUserData(null)
          }
        })();
      }, [user]);

    

    return (
        <AuthContext.Provider value={{ signUp,logIn, logOut, addEvent, signUpSecondStep, resetpass, user, userData }}>
            {children}
        </AuthContext.Provider>
    );
};

export const UserAuth = () => useContext(AuthContext);