/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-template */
/* eslint-disable no-else-return */
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Paper,
  TableHead,
  Grid,
  CardActions,
  CardContent,
  Box,
  Modal,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Chip,
  OutlinedInput,
  MenuItem,
  useTheme,
  Tooltip,
  IconButton,
  Backdrop,
  CircularProgress,
  Skeleton,
  Menu,
  styled,
  alpha,
} from '@mui/material';
// components
import { collection, deleteDoc, doc, getDoc, getDocs, query } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';

// mock
import USERLIST from '../../_mock/user';

import { db, functions } from '../../firebase.config';
import CheckCard from '../../components/CheckCard';
import { UserAuth } from '../../context/AuthContext';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

// ----------- for more bouton modal
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 5,
    marginTop: theme.spacing(1),
    minWidth: 200,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 15,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

// for modal
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function Operateurs() {
  const { eventID } = useParams();
  const { user } = UserAuth();
  const theme = useTheme();
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (e) => {
    console.log('cell click ', e.target.id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData('Tarif Nomarl', '-', 'Infini', 5000, 4.0),
    createData('Tarif VIP', '-', 9.0, 10000, 4.3),
    createData('Tarif VVIP', '-', 16.0, 20000, 6.0),
  ];

  // for change value of globla quota
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [refreh, setRefresh] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    // setModif(false);
    setRefresh((prev) => !prev);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  // take operateurs from firebase
  const [ops, setOp] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      setOp([]);
      setPassview([]);
      try {
        setLoading(true);
        const q = query(collection(db, `evenement/${eventID}/operateurs`));
        const snapshot = await getDocs(q);
        snapshot.forEach(async (d) => {
          const authInfoSnap = await getDoc(doc(db, 'authLinks', d.data().link.split('token=')[1]))
          console.log(authInfoSnap.data())
          setOp((ops) => [...ops, { id: d.id, opData: d.data(), authLink: authInfoSnap.data() }]);

        });
        setLoading(false);
      } catch (error) {
        console.log('error');
      }
    })();
  }, [refreh]);

  // loading
  const [loading, setLoading] = React.useState(false);

  // GESTION DE LA MODIFICATION
  const [temp, setTemp] = React.useState([]);
  const [name, setName] = React.useState();
  const [id, setId] = React.useState();
  const [pass, setPass] = React.useState();

  // *****ticket
  const [ticketVerif, setTicket] = React.useState([]);
  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }

  // *****recuperation de billets

  const [tickets, ticketList] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      ticketList([])
      try {
        // setLoading(true);
        const q = query(collection(db, `evenement/${eventID}/tickets`));
        const snapshot = await getDocs(q);
        snapshot.forEach((d) => {
          ticketList((tickets) => [...tickets, d.data()]);
        });
        // setLoading(false);
      } catch (error) {
        console.log('error');
      }
    })();
  }, []);

  const [personName, setPersonName] = React.useState([]);
  const handleChangeTicket = (event) => {
    const {
      target: { value },
    } = event;
    setTicket(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
    console.log(ticketVerif);
  };

  const [modif, setModif] = React.useState(false);
  const handleModif = (index) => {
    if (modif) {
      setModif(false);
    } else {
      const temp = ops[index].data();
      console.log(index);
      console.log(temp.tickets);
      setName(temp.nameOp);
      setId(temp.idOp);
      setPass(temp.passOp);
      setTicket(temp.tickets);
      setModif(true);
    }
  };

  const handleSupp = async (index) => {
    handleToggle();
    console.log('supp');
    const op = ops[index];

    try {
      await deleteDoc(doc(db, `evenement/${eventID}/operateurs`, `${op.id}`));
    } catch (error) {
      console.log(error.message);
      handleClose();
    }
    handleClose();
  };

  const handleActive = async () => {
    setLoading(true)
    const generateAuthLink = httpsCallable(functions, "generateAuthLink");
    try {
      const response = await generateAuthLink({
        operatorID: id,
        name,
        eventID
      });
      console.log('link');
      console.log(response.data);

       
      // setMsg({ content: 'Opérateur créé avec succès', color: 'green' });
      // setOpenSnack(true);
      // navigate(-1, { replace: true });
    } catch (error) {
      console.log('error');
      console.log(error.message);
      // setMsg({ content: "une erreur est survenue", color: 'red' });
      // setOpenSnack(true);
    }
    setLoading(false)
  };

  const handleDeactive = async () => {
    setLoading(true)
    const generateAuthLink = httpsCallable(functions, "generateAuthLink");
    try {
      const response = await generateAuthLink({
        operatorID: id,
        name,
        eventID
      });
      console.log('link');
      console.log(response.data);

       
      // setMsg({ content: 'Opérateur créé avec succès', color: 'green' });
      // setOpenSnack(true);
      // navigate(-1, { replace: true });
    } catch (error) {
      console.log('error');
      console.log(error.message);
      // setMsg({ content: "une erreur est survenue", color: 'red' });
      // setOpenSnack(true);
    }
    setLoading(false)
  };

  const resendEmail = async () => {
    setLoading(true)
    const generateAuthLink = httpsCallable(functions, "generateAuthLink");
    try {
      const response = await generateAuthLink({
        operatorID: id,
        name,
        eventID
      });
      console.log('link');
      console.log(response.data);

       
      // setMsg({ content: 'Opérateur créé avec succès', color: 'green' });
      // setOpenSnack(true);
      // navigate(-1, { replace: true });
    } catch (error) {
      console.log('error');
      console.log(error.message);
      // setMsg({ content: "une erreur est survenue", color: 'red' });
      // setOpenSnack(true);
    }
    setLoading(false)
  };

  const handleSubmit = async (e) => { };
  const [passView, setPassview] = React.useState([]);



  const [selectedIndex, setSelectedIndex] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const handleOpenMenu = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
  };
  const handleCloseMenu = (e) => {

    setAnchorEl(null);
    setOpenMenu(false);
  };

  return (
    <Page title="Opérateurs">
      <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container sx={{ marginTop: { xs: 3 } }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Opérateurs Scan<br />
            <Typography variant="body2">
              Les profils d'opérateurs vous permettent de vous identifier sur nos appareils de contrôle d'accès,
              <br /> puis de scanner les participants à votre événement.
            </Typography>
          </Typography>
          <Button
            sx={{
              textTransform: 'initial',
              border: '2px solid transparent',
              color: 'white',
              backgroundColor: '#5E17EB',
              borderRadius: '7px',
              '&:hover': {
                backgroundColor: 'white',
                color: '#5E17EB',
                borderColor: '#5E17EB',
              },
            }}
            variant="contained"
            component={RouterLink}
            to="ajout"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Nouvel opérateur
          </Button>
        </Stack>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                height: '100%',
                borderRadius: '7px',
                padding: 3,
                border: '2px solid transparent',
                cursor: 'pointer',
                bgcolor: '#f2f2f2',
              }}
            >
              <Typography textAlign="Start">
                <strong>Vos identifiants scan</strong>
              </Typography>
              <Typography variant='body2' textAlign="Start">
                Pour utiliser l'app de scan, vous pouvez vous connecter avec vos information de connexion. Mais vous pouvez aussi créer des identitifiant additionnel pour le
              </Typography>
              <Stack spacing={2} paddingTop={2}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2">
                    <strong>identitifiant :</strong>
                  </Typography>
                  <Typography variant="body2">
                    beschristyann@gmail.com
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2">
                    <strong>Mot de passe :</strong>
                  </Typography>
                  <Typography variant="body2">
                    *******
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} paddingTop={2}>
            <Scrollbar>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="caption table">
                  <TableHead sx={{bgcolor:'#f2f2f2'}}>
                    <TableRow>
                      <TableCell align="left">Opérateur</TableCell>
                      <TableCell align="center">Lien de connexion</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  {loading ? (
                    <caption>
                      <Skeleton variant="rounded" width="100%" />
                    </caption>
                  ) : ops.length === 0 && !loading ? (
                    <caption>
                      <Stack spacing={1} sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        <Iconify icon="icomoon-free:drawer2" sx={{ width: 30, height: 30 }} />
                        <Typography variant="caption">Aucune donnée</Typography>
                      </Stack>
                    </caption>
                  ) : (
                    <TableBody>
                      {ops.map((op, index) => (
                        <TableRow key={index} hover>
                          <TableCell
                            id={op.opData.name}

                            key={op.opData.name}
                            align="left"
                            component="th"
                            scope="row"
                          >
                            <Stack>
                              <Typography variant='body2'>{op.opData.nameOp}</Typography>
                              <Stack direction={'row'} spacing={1}>
                                {
                                  op.authLink.isConnected ?
                                    <Box sx={{ bgcolor: '#ADFFD5', borderRadius: '10px', paddingX: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                      <Typography fontSize={11} sx={{ color: '#006832' }} variant='caption'>Connecté</Typography>
                                    </Box>
                                    :
                                    <Box sx={{ bgcolor: '#FFD6D6', borderRadius: '10px', paddingX: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                      <Typography fontSize={11} sx={{ color: '#720000' }} variant='caption'>Non connecté</Typography>
                                    </Box>
                                }

                                {
                                  !op.authLink.isActive &&
                                  <Box>
                                    <Typography fontSize={11} variant='caption'>Inactif</Typography>
                                  </Box>


                                }

                              </Stack>

                              <Typography variant='body2'> {op.opData.idOp}</Typography>
                            </Stack>

                          </TableCell>
                          <TableCell id={op.opData.link} align="center">
                            {op.opData.link}
                          </TableCell>
                          <TableCell id={op.opData.name} align="center">
                            <IconButton  key={index} onClick={(e) => handleOpenMenu(e, index)}
                              aria-controls={openMenu ? 'demo-customized-menu' : undefined}
                              aria-haspopup="true"
                              aria-expanded={openMenu ? 'true' : undefined}>
                              <Iconify icon="material-symbols-light:more-vert" />
                            </IconButton>
                            <StyledMenu
                              id="demo-customized-menu"
                              MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                              }}
                              anchorEl={anchorEl}
                              open={openMenu}
                              onClose={handleCloseMenu}
                            >

                              <MenuItem key={`${index}-1`} onClick={(e) => {
                                handleCloseMenu();
                                // handleModif(selectedIndex); // Utilise l'index sélectionné
                              }} disableRipple value={1}>
                                <Typography variant='body2'>Renveoyer le lien</Typography>
                              </MenuItem>

                              <MenuItem
                                onClick={(e) => {
                                  handleCloseMenu();
                                }}
                                disableRipple
                                value={0}
                              >
                                <Typography variant='body2'>Copier le lien</Typography>
                              </MenuItem>
                              {
                                ops[selectedIndex]?.opData?.status === 1 ?
                                  <MenuItem
                                    onClick={(e) => {
                                      handleCloseMenu();
                                      // handleVisible()
                                    }}
                                    disableRipple
                                    value={2}
                                  >
                                    <Typography variant='body2'>Désactiver</Typography>
                                  </MenuItem>
                                  : ops[selectedIndex]?.data?.status === 0 ?
                                    <MenuItem
                                      onClick={(e) => {
                                        handleCloseMenu();
                                        // handleVisible()
                                      }}
                                      disableRipple
                                      value={2}
                                    >
                                      <Typography variant='body2'>Activer</Typography>
                                    </MenuItem> : null
                              }
                              <MenuItem
                                onClick={(e) => {
                                  handleCloseMenu();
                                  // handleOpenDeleteModal()
                                }}
                                disableRipple
                                value={2}
                              >
                                <Typography variant='body2'>Supprimer</Typography>
                              </MenuItem>

                            </StyledMenu>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
