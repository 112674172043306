/* eslint-disable no-plusplus */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
import * as Yup from 'yup';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Paper,
  TableHead,
  Grid,
  CardActions,
  CardContent,
  Box,
  Modal,
  TextField,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Switch,
  FormControlLabel,
  Divider,
  Backdrop,
  CircularProgress,
  IconButton,
  Tooltip,
  Skeleton,
  Snackbar,
  Alert,
  ToggleButton,
  ToggleButtonGroup,
  styled,
  Menu,
  alpha,
  MenuItem,
} from '@mui/material';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  updateDoc,
  where,
  orderBy,
  Timestamp,
} from 'firebase/firestore';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { db } from '../../firebase.config';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../sections/@dashboard/user';
import { FormProvider } from '../../components/hook-form';
// mock
import USERLIST from '../../_mock/user';
import CheckCard from '../../components/CheckCard';
import { Variables } from '../../context/VariableContext';
import CustomButton from '../../components/CustomButton';
import CustomDateTimePicker from '../../components/CustomDateTimePicker';
import { shortNumber } from '../../utils/formatNumber';
import ModifyTicket from './billet/modifyBillet';

// ----------------------------------------------------------------------

// Toogle Group Button
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: 0.5,
    width: 60,
    height: 30,
    border: 0,
    // background: 'black',
    '&.Mui-disabled': {
      border: 0,
    },
    '&.Mui-selected': {
      border: 0,
      background: '#5E17EB',
      color: 'white',
      '&:hover': {
        backgroundColor: '#5E17EB',

      },
    },
    '&:not(:first-of-type)': {
      // background: 'red',
      borderRadius: '7px',
    },
    '&:first-of-type': {
      // background: 'black',
      borderRadius: '7px',
    },
  },
}));
//-----------------------------------------------------------------------

// for modal
const style = {
  position: 'absolute',
  top: '25%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 350,
  width: 500,
  bgcolor: 'background.paper',
  p: 4,
};
const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 600,
  bgcolor: 'white',
  border: '1px solid white',
  borderRadius: '5px',
  boxShadow: 0,
  p: 3,
};

// ----------- for more bouton modal
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 5,
    marginTop: theme.spacing(1),
    minWidth: 200,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 15,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export default function Billets() {
  const { eventID } = useParams();
  const navigate = useNavigate();
  const { generalReload, setGeneralReload } = Variables()
  const [page, setPage] = useState(0);

  const [selected, setSelected] = useState([]);

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  // for change value of globla quota
  const [open, setOpen] = React.useState(false);
  const [refreh, setRefresh] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setModif(false);
    setRefresh((prev) => !prev);
  };

  // take tickets from firebase
  const [event, setEvent] = useState();
  const [tickets, setTicket] = React.useState([]);

  const [modif, setModif] = React.useState(false); // ouvrir section de modification

  const [curIndex, setIndex] = React.useState(); // l'index du billet a modifier

  // config des champs
  const [libelle, setLibelle] = React.useState(''); // le nom du ticket
  const [libelleError, setLibelleError] = React.useState(false);
  const [prix, setPrix] = React.useState(0);
  const [quantite, setQuantite] = React.useState(0); // fixer le quota
  const [desc, setDesc] = React.useState(''); // ajouter une description au billet
  const [limitedQt, setLinmitedQt] = React.useState(false); // activer le quota
  const [montant, setMontant] = React.useState(0); // rensiegner le prix du ticket
  const [prixError, setPrixError] = React.useState(false);
  const [quantiteError, setQuantiteError] = React.useState(false);
  const [commission, setCommission] = React.useState(0); // commission
  const [revenu, setRevenu] = React.useState(0); // revenu
  const [checked, setChecked] = React.useState(false);
  const RegisterSchema = Yup.object().shape({
    nom: Yup.string().required('Nom est obligatoire'),
    // lastName: Yup.string().required('Prénom est obliagtoire'),
    // email: Yup.string().email('Email doit est être un email valide').required('Email est obligatoire'),
    // password: Yup.string().required('Mot de passe est obligatoire'),
  });

  const defaultValues = {
    nom: 'ticket',
    // lastName: '',
    // email: '',
    // password: '',
  };

  //  snack bar
  const [openSnack, setOpenSnack] = React.useState(false);
  const [msg, setMsg] = React.useState({ content: '', color: '' });
  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  // convert timestamp

  const handleTimestamp = (e) => {
    console.log('handle time stamp')
    const date = e.toDate();
    return format((new Date(date.toJSON())), 'yyyy-MM-dd', { locale: fr }).toString();
  };

  const handleTimestampCustom = (e) => {
    const date = e.toDate();
    const tmp = date.toJSON().split('.')[0].split('T')[1].split(':');
    return format((new Date(date.toJSON())), 'HH:mm', { locale: fr }).toString();
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleChange = () => {
    if (checked) {
      setMontant(0);
    }
    setChecked((prev) => !prev);
    handleFieldChange()
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const onSubmit = async (e) => {
    // fonction de mise a jour des billet
    try {
      handleToggle();
      let tmp = false;
      setPrixError(false);
      setQuantiteError(false);
      const tmpDateDebut = new Date(dateDebut);
      // Utiliser setHours pour remplacer l'heure et les minutes
      tmpDateDebut.setHours(timeDebut.split(':')[0], timeDebut.split(':')[1], 0);
      const tmpDateFin = new Date(dateFin);
      // Utiliser setHours pour remplacer l'heure et les minutes
      tmpDateFin.setHours(timeFin.split(':')[0], timeFin.split(':')[1], 0);
      if (checked && montant === 0) {
        setPrixError(true);
        tmp = true;
      }
      if ((limitedQt && quantite === 0) || quantite < tickets[curIndex].data().sell) {
        setQuantiteError(true);
        tmp = true;
      }
      if (!tmp) {
        const statRef = doc(db, `evenement/${eventID}/tickets`, `${tickets[curIndex].id}`);
        await updateDoc(statRef, {
          ticket_name: libelle,
          quota: limitedQt ? parseInt(quantite) : parseInt(0),
          price: !checked ? parseInt(0) : parseInt(montant),
          description: desc,
        });

        if (montant !== tickets[curIndex].data().price && tickets[curIndex].data().sell > 0) {
          const logRef = collection(db, `evenement/${eventID}/tickets/${tickets[curIndex].id}/logs`);
          await addDoc(logRef, {
            type: 'modification du prix du billet',
            old: tickets[curIndex].data().price,
            new: parseInt(montant),
          });
        }
        // plannification du billet
        if (autoVente) {
          if (tickets[curIndex].data().automation) {
            const automateId = tickets[curIndex].data().automation
            const tmpDateDebut = new Date(dateDebut);
            // Utiliser setHours pour remplacer l'heure et les minutes
            tmpDateDebut.setHours(timeDebut.split(':')[0], timeDebut.split(':')[1], 0);
            const tmpDateFin = new Date(dateFin);
            // Utiliser setHours pour remplacer l'heure et les minutes
            tmpDateFin.setHours(timeFin.split(':')[0], timeFin.split(':')[1], 0);
            const auto = await updateDoc(doc(db, `automationTickets/scheduling/automations`, automateId), {
              type: 0,
              debut: tmpDateDebut ? Timestamp.fromDate(tmpDateDebut) : Timestamp.fromDate(new Date()),
              fin: tmpDateFin ? Timestamp.fromDate(tmpDateFin) : null,
            });
            await updateDoc(doc(db, `automationTickets`, `scheduling`), {
              update: true,
            });

            await updateDoc(statRef, {
              status: 2,
            });
          } else {
            const tmpDateDebut = new Date(dateDebut);
            // Utiliser setHours pour remplacer l'heure et les minutes
            tmpDateDebut.setHours(timeDebut.split(':')[0], timeDebut.split(':')[1], 0);
            const tmpDateFin = new Date(dateFin);
            // Utiliser setHours pour remplacer l'heure et les minutes
            tmpDateFin.setHours(timeFin.split(':')[0], timeFin.split(':')[1], 0);

            const auto = await addDoc(collection(db, `automationTickets/scheduling/automations`), {
              event: eventID,
              ticket: tickets[curIndex].id,
              type: 0,
              debut: tmpDateDebut ? Timestamp.fromDate(tmpDateDebut) : Timestamp.fromDate(new Date()),
              fin: tmpDateFin ? Timestamp.fromDate(tmpDateFin) : null,
            });
            await updateDoc(doc(db, `automationTickets`, `scheduling`), {
              update: true,
            });

            await updateDoc(statRef, {
              status: 2,
              automation: auto.id,
            });

          }

        }
        console.log(event.minPrice)
        // define the min price
        let lowestPrice = tickets[0];
        for (let i = 1; i < tickets.length; i++) {
          // Vérifier si le prix du ticket actuel est inférieur au prix le plus bas trouvé jusqu'à présent
          if (tickets[i] < lowestPrice) {
            // Si oui, mettre à jour le prix le plus bas
            lowestPrice = tickets[i];
          }
        }
        if (montant !== tickets[curIndex].data().price && parseInt(montant) < lowestPrice) {
          const eventRef = doc(db, `evenement/${eventID}`);
          await updateDoc(eventRef, {
            minPrice: parseInt(montant)
          });
        }
        setMsg({ content: 'Le billet a été modifié avec succès ', color: 'green' });
        setOpenSnack(true);
        handleClose();
      }
      setIsModified(false)
    } catch (error) {
      console.log(error.message);
      setMsg({ content: 'Une erreur est survenue', color: 'red' });
      setOpenSnack(true);
    }
    setOpen(false);
    // navigate(-1, { replace: true });
  };

  const handleMontant = (event) => {
    setMontant(event.target.value);
    if (event.target.value !== 0) {
      console.log('montant =', event.target.value);
      const tmp = event.target.value * 5;

      const tmp1 = tmp / 100;

      setCommission(tmp1);

      setRevenu(event.target.value - tmp1);
    }
    handleFieldChange()
  };

  const handleLimitedQt = () => {
    if (limitedQt) {
      setQuantite(0);
    }
    setLinmitedQt((prev) => !prev);
  };

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      setTicket([]);
      try {
        const docRef0 = doc(db, 'evenement', eventID);
        const docSnap = await getDoc(docRef0);
        if (docSnap.exists()) {
          setEvent(docSnap.data());
        }

        const q = query(collection(db, `evenement/${eventID}/tickets`));
        const snapshot = await getDocs(q);
        snapshot.forEach((d) => {
          setTicket((tickets) => [...tickets, d]);
          console.log(d.data())
        });
        /* const q1 = query(
          collection(db, `automationTickets/scheduling/automations`),
          where('fin', '!=', null),
          orderBy('fin', 'asc'),
          limit(1)
        );
        const snapshot1 = await getDocs(q1);
        snapshot1.forEach((d) => {
          console.log(d.data());
        }); */
        // const last1 = await admin.firestore().collection('automationTickets').doc(docu.id).collection('automations').orderBy('debut', 'asc').limit(1).get();
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();
  }, [refreh]);

  // loading
  const [loading, setLoading] = React.useState(false);

  // page de modification
  const [openModif, setOpenModif] = React.useState(false);
  const handleCloseModif = () => setOpenModif(false);
  const [temp, setTemp] = React.useState([]);

  // modification d'un billet
  const handleModif = async (index) => {
    setLoading(true);
    navigate(`${tickets[index].id}`)
    if (modif) {
      setModif(false);
    } else {
      setIndex(index);
      setLibelle(tickets[index].data().ticket_name);
      setDesc(tickets[index].data().description);
      setMontant(parseInt(tickets[index].data().price));
      if (parseInt(tickets[index].data().price) !== 0) {
        setChecked(true);
      } else if (parseInt(tickets[index].data().price) === 0) {
        setChecked(false);
      }
      setQuantite(parseInt(tickets[index].data().quota));
      if (parseInt(tickets[index].data().quota) !== 0) {
        setLinmitedQt(true);
      } else if (parseInt(tickets[index].data().quota) === 0) {
        setLinmitedQt(false);
      }
      if (parseInt(tickets[index].data().price) !== 0) {
        console.log('montant =', parseInt(tickets[index].data().price));
        const tmp = parseInt(tickets[index].data().price) * 5;

        const tmp1 = tmp / 100;

        setCommission(tmp1);

        setRevenu(parseInt(tickets[index].data().price) - tmp1);
      }
      // check automation
      if (tickets[index].data().automation) {
        setAdvancedConfig(true)
        setAutoVente(true)
        console.log(tickets[index].data().automation)
        try {
          const automationDoc = await getDoc(doc(db, `automationTickets/scheduling/automations`, tickets[index].data().automation));
          if (automationDoc.exists()) {
            console.log(automationDoc.data())
            setDateDebut(handleTimestamp(automationDoc.data().debut))
            setTimeDebut(handleTimestampCustom(automationDoc.data().debut))
            setDateFin(handleTimestamp(automationDoc.data().fin))
            setTimeFin(handleTimestampCustom(automationDoc.data().fin))
          }


        } catch (error) {
          console.log(error)
          setMsg({ content: 'Une erreur est survenue', color: 'red' });
          setOpenSnack(true);
        }

      }
      console.log(curIndex);
      setModif(true);
    }
    setLoading(false);
  };
  const back = () => {
    setLoading(true);

    setIndex('');
    setLibelle('');
    setDesc('');
    setMontant();
    // check automation
    setAdvancedConfig(false)
    setAutoVente(false)


    setLoading(false);
    setModif(false);
    setIsModified(false)
  };
  // supression d'un billet
  // Modal deleted
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);
  const handleSupp = async (index) => {
    handleToggle();
    console.log('supp');
    const ticket = tickets[index];
    if (ticket.data().sell === 0) {
      try {
        await deleteDoc(doc(db, `evenement/${eventID}/tickets`, `${ticket.id}`));
        setMsg({ content: 'Le billet a été supprimé avec succès ', color: 'green' });
        setGeneralReload(!generalReload)
        setOpenSnack(true);
      } catch (error) {
        console.log(error.message);
        setMsg({ content: 'Une erreur est survenue', color: 'red' });
        setOpenSnack(true);
        handleClose();
      }
    }
    handleClose();
    handleCloseDeleteModal();
  };

  // desactiver le billet
  const handleVisible = async () => {
    const ticket = tickets[selectedIndex];
    if (ticket.data().status === 0) {
      try {
        handleToggle();
        const statRef = doc(db, `evenement/${eventID}/tickets`, `${ticket.id}`);

        await updateDoc(statRef, {
          status: 1,
        });
        setMsg({ content: 'Le Billet a été activé avec succès ', color: 'green' });
        setOpenSnack(true);
      } catch (error) {
        setMsg({ content: 'Une erreur est survenue', color: 'red' });
        setOpenSnack(true);
        handleClose();
        console.log(error.message);
      }
    } else if (ticket.data().status === 1) {
      try {
        handleToggle();
        const statRef = doc(db, `evenement/${eventID}/tickets`, `${ticket.id}`);
        await updateDoc(statRef, {
          status: 0,
        });
        setMsg({ content: 'Le Billet a été desactivé avec succès ', color: 'green' });
        setOpenSnack(true);
      } catch (error) {
        setMsg({ content: 'Une erreur est survenue', color: 'red' });
        setOpenSnack(true);
        handleClose();
        console.log(error.message);
      }
    }
    handleClose();
  };
  // page de d'activation/desactivation
  const handleActivation = (index) => {
    const temp = tickets[index];
  };

  const [advancedConfig, setAdvancedConfig] = React.useState(false);
  // programmation
  const [salePeriod, setSalePeriod] = React.useState(0);
  const [autoVente, setAutoVente] = React.useState(false);

  // programmation
  const [quantitePerClient, setQuantitePerClient] = React.useState();

  const handleAutoVente = (e) => {
    setSalePeriod(e);
    setAutoVente((prev) => !prev);
  };
  const [debutAutoVente, setDebutAutoVente] = React.useState('');
  const [finAutoVente, setFinAutoVente] = React.useState('');

  /** new programmation billet */
  // programmation
  // date / time
  const [dateDebut, setDateDebut] = React.useState(null);
  const [timeDebut, setTimeDebut] = React.useState('');

  const handleDateDebutChange = (newDate) => {
    setDateDebut(newDate);
  };

  const handleTimeDebutChange = (newTime) => {
    console.log('parent')
    setTimeDebut(newTime);
  };
  const [dateFin, setDateFin] = React.useState(null);
  const [timeFin, setTimeFin] = React.useState('');

  const handleDateFinChange = (newDate) => {
    setDateFin(newDate);
  };

  const handleTimeFinChange = (newTime) => {
    setTimeFin(newTime);
  };

  const [selectedIndex, setSelectedIndex] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const handleOpenMenu = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
  };
  const handleCloseMenu = (e) => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const [isModified, setIsModified] = React.useState(false);

  const handleFieldChange = () => {
    setIsModified(true);
  };


  return (
    <Page title="Mes Billets">
      <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {loading && <LinearProgress />}
      <Container sx={{ marginTop: { xs: 3 } }}>
        <Modal
          open={openModif}
          onClose={handleCloseModif}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Quota Maximal
            </Typography>
            <TextField
              value={temp.quota}
              size="small"
              fullWidth
              id="outlined-number"
              placeholder="Entrer le quota"
              type="number"
            />
            <Typography variant="body2" sx={{ mt: 2 }}>
              le quota est la capaciter maximale de personne que vous souhaitez acceuillir.
            </Typography>
            <Button onClick={handleOpen} size="small">
              Enregistrer
            </Button>
          </Box>
        </Modal>
        {/** popup de suppression du billet */}
        <Modal
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ borderRadius: '7px' }}
        >
          <Box sx={styleModal}>
            <Stack spacing={4}>
              {
                tickets[selectedIndex]?.data()?.sell > 0 ?
                  <>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Typography variant="subtitle1">Impossible de supprimer le billet</Typography>
                    </Box>
                    <Box sx={{ marginTop: 5 }} dir="ltr">
                      <Typography align='center' variant='body2'>Vous ne pouvez pas supprimer ce billet car il a déjà été commandé par des participants.</Typography>
                    </Box>
                    <Stack direction='row' spacing={2} justifyContent='center'>
                      <CustomButton type='secondaire' buttonText='Ok' onClick={handleCloseDeleteModal} />
                    </Stack>
                  </>
                  :
                  <>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Typography variant="subtitle1">Supprimer le billet</Typography>
                    </Box>
                    <Box sx={{ marginTop: 5 }} dir="ltr">
                      <Typography align='center' variant='body2'>Voulez-vous vraiment supprimer le billet <strong>{tickets[selectedIndex]?.data()?.ticket_name}</strong> ? Cette action ne peut pas être annulée.</Typography>
                    </Box>
                    <Stack direction='row' spacing={2} justifyContent='center'>
                      <CustomButton type='secondaire' buttonText='Annuler' onClick={handleCloseDeleteModal} />
                      <CustomButton type='primaire' buttonText='Supprimer' onClick={() => handleSupp(selectedIndex)} />
                    </Stack>
                  </>
              }


            </Stack>

          </Box>
        </Modal>

        <>
          {/* en tete de la page */}
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h3" gutterBottom>
              Billet & Mise en vente <br />
              <Typography variant="body2">Creez et modifiez vos billets</Typography>
            </Typography>
            <CustomButton type='primaire' buttonText='Ajouter' startIcon={<Iconify icon="eva:plus-fill" sx={{ width: 20, height: 20 }} />} component={RouterLink}
              to="nouveau_billet" />
          </Stack>

          {/* Tableau des billets */}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Scrollbar>
              <TableContainer component={Paper} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Table sx={{ minWidth: 500, maxWidth: 1000 }} aria-label="caption table">
                  {loading ? (
                    <caption>
                      <Skeleton variant="rounded" width="100%" />
                    </caption>
                  ) : tickets.length === 0 && !loading ? (
                    <caption>
                      <Stack spacing={1} sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        <Iconify icon="icomoon-free:drawer2" sx={{ width: 30, height: 30 }} />
                        <Typography variant="caption">Aucun billet</Typography>
                      </Stack>
                    </caption>
                  ) : (
                    <TableBody>
                      {tickets.map((ticket, index) => (
                        <TableRow key={index} hover sx={{ cursor: 'pointer' }}>
                          <TableCell component="th" scope="row" onClick={() => handleModif(index)}>
                            <Stack>
                              <Typography variant='h6'>{ticket.data().ticket_name}</Typography>
                              <Stack direction='row' spacing={1} alignItems='center'>
                                {
                                  ticket.data().sell !== 0 && ticket.data().sell >= ticket.data().quota ?
                                    <>
                                      <Iconify icon='radix-icons:dot-filled' sx={{ color: 'red' }} />
                                      <Typography variant='body2'>Complet</Typography>
                                    </>
                                    : ticket.data().status === 1 ?
                                      <>
                                        <Iconify icon='radix-icons:dot-filled' sx={{ color: 'green' }} />
                                        <Typography variant='body2'>En Vente</Typography>
                                      </>
                                      : ticket.data().status === 2 ?
                                        <>
                                          <Iconify icon='radix-icons:dot-filled' sx={{ color: 'yellow' }} />
                                          <Typography variant='body2'>Planifié</Typography>
                                        </> : null

                                }

                                {
                                  ticket.data().status === 0 && <Stack direction='row' spacing={0.5} alignItems='center'>
                                    <Iconify icon="gridicons:not-visible" />
                                    <Typography variant='body2'>Masqué</Typography>
                                  </Stack>
                                }

                              </Stack>
                            </Stack>

                          </TableCell>
                          <TableCell align="right" onClick={() => handleModif(index)}>
                            <Stack direction='row' spacing={1}>
                              <Typography variant="body2">{ticket.data().sell} <span color='#f2f2f2'>{ticket.data().encours > 0 ?  `+${ticket.data().encours} encours`: null}</span>  {event?.seanceActive !== 1 ? `/ ${ticket.data().quota}` : ''}</Typography>
                              <Typography variant="body2">Billet(s) vendu(s)</Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="right" onClick={() => handleModif(index)}>
                            {ticket.data().price !== 0 ? (
                              <Typography variant="body2">{shortNumber(ticket.data().price)} CFA</Typography>
                            ) : (
                              <Typography variant="body2">Gratuit</Typography>
                            )}
                          </TableCell>

                          <TableCell align="right">
                            <IconButton key={index} onClick={(e) => handleOpenMenu(e, index)}
                              aria-controls={openMenu ? 'demo-customized-menu' : undefined}
                              aria-haspopup="true"
                              aria-expanded={openMenu ? 'true' : undefined} sx={{ border: '1px solid gainsboro' }}>
                              <Iconify icon="material-symbols-light:more-vert" />
                            </IconButton>
                            <StyledMenu
                              id="demo-customized-menu"
                              MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                              }}
                              anchorEl={anchorEl}
                              open={openMenu}
                              onClose={handleCloseMenu}
                            >

                              <MenuItem key={`${index}-1`} onClick={(e) => {
                                handleCloseMenu();
                                handleModif(selectedIndex); // Utilise l'index sélectionné
                              }} disableRipple value={1}>
                                <Typography variant='body1'>Modifier</Typography>
                              </MenuItem>

                              <MenuItem
                                onClick={(e) => {
                                  handleCloseMenu();
                                }}
                                disableRipple
                                value={0}
                              >
                                <Typography variant='body1'>Copier</Typography>
                              </MenuItem>
                              {
                                tickets[selectedIndex]?.data().status === 1 ?
                                  <MenuItem
                                    onClick={(e) => {
                                      handleCloseMenu();
                                      handleVisible()
                                    }}
                                    disableRipple
                                    value={2}
                                  >
                                    <Typography variant='body1'>Désactiver</Typography>
                                  </MenuItem>
                                  : tickets[selectedIndex]?.data().status === 0 ?
                                    <MenuItem
                                      onClick={(e) => {
                                        handleCloseMenu();
                                        handleVisible()
                                      }}
                                      disableRipple
                                      value={2}
                                    >
                                      <Typography variant='body1'>Activer</Typography>
                                    </MenuItem> : null
                              }
                              <MenuItem
                                onClick={(e) => {
                                  handleCloseMenu();
                                  handleOpenDeleteModal()
                                }}
                                disableRipple
                                value={2}
                              >
                                <Typography variant='body1'>Supprimer</Typography>
                              </MenuItem>

                            </StyledMenu>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
          </Box>
        </>

        <Snackbar
          open={openSnack}
          autoHideDuration={5000}
          onClose={handleCloseSnack}
          message={
            <Stack spacing={1} direction={'row'} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography variant='caption'>{msg.content}{' '}</Typography>
              <Iconify
                sx={{ color: msg.color }}
                icon={`${msg.red ? 'system-uicons:cross-circle' : 'mdi:success-circle-outline'}`}
              />
            </Stack>
          }
        />
      </Container>
    </Page>
  );
}
