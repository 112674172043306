/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Paper,
    TableHead,
    Grid,
    CardActions,
    CardContent,
    Box,
    Modal,
    TextField,
    LinearProgress,
    IconButton,
    Tooltip,
    Backdrop,
    CircularProgress,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Switch,
    FormGroup,
    Snackbar,
    Skeleton,
    Alert,
} from '@mui/material';
// components
import { addDoc, collection, deleteDoc, doc, getDocs, query, Timestamp, updateDoc } from 'firebase/firestore';
import { fr } from 'date-fns/locale';
import { format } from 'date-fns';
import { db } from '../../../firebase.config';
import Page from '../../../components/Page';
import Label from '../../../components/Label';
import Scrollbar from '../../../components/Scrollbar';
import Iconify from '../../../components/Iconify';
import CustomButton from '../../../components/CustomButton';


// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

// for modal
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};
const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 400,
    bgcolor: 'white',
    border: '1px solid white',
    borderRadius: '7px',
    boxShadow: 0,
    p: 3,
};

export default function Affiliate() {
    const [page, setPage] = useState(0);
    const { eventID } = useParams();
    const navigate = useNavigate()
    // loading
    const [loading, setLoading] = React.useState(false);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('name');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };



    function createData(name, calories, fat, carbs, protein) {
        return { name, calories, fat, carbs, protein };
    }
    // modification de code
    const [nomDuCode, setNom] = React.useState(''); // valeur de la reduction
    const [codeASaisir, setASaisir] = React.useState(''); // valeur de la reduction
    const [nbUtilisation, setNb] = React.useState(0); // valeur de la reduction
    const [reduction, setReduction] = React.useState(0); // valeur de la reduction
    const [debutField, setDebut] = React.useState(); // valeur de la reduction
    const [finField, setFin] = React.useState(); // valeur de la reduction
    const [limitedT, setLimit] = React.useState(false); //
    const [period, setPeriod] = React.useState(false); // activer un periode de validité
    const [reductionType, setReductionType] = React.useState('fixe'); // reduction type
    // recuperation de billets
    const [tickets, ticketList] = React.useState([]); // contient la liste des tickets
    const [checks, setCheck] = React.useState([]);

    // for change value of globla quota
    const [modif, setModif] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [refreh, setRefresh] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setModif(false);
        setRefresh((prev) => !prev);
    };

    //  snack bar
    const [openSnack, setOpenSnack] = React.useState(false);
    const [msg, setMsg] = React.useState({ content: '', color: '' });
    const handleCloseSnack = () => {
        setOpenSnack(false);
    };

    // setup des codes promo
    const [affiliations, setAffiliations] = useState([]);
    const [curent, setCurent] = useState();
    React.useEffect(() => {
        (async () => {
            setAffiliations([]);
            try {
                setLoading(true);
                const q = query(collection(db, `evenement/${eventID}/affiliations`));
                const snapshot = await getDocs(q);
                snapshot.forEach((d) => {
                    setAffiliations((affiliations) => [...affiliations, d]);
                    // console.log(d.id, ' => ', d.data());
                });
                setLoading(false);
            } catch (error) {
                console.log('error');
            }
        })();
    }, [refreh]);

    const handleToggle = () => {
        setOpen(!open);
    };
    const handleVisible = async (index) => {
        //
    };
    const handleModif = async (index) => {
        setLoading(true);
        setCurent(index);
        try {
            //
        } catch (error) {
            console.log(error);
        }
        setModif(true);
        setCurent(index);
        setLoading(false);
    };


    const handleTimestamp = (e) => {
        const date = e.toDate();
        const tmp = date.toJSON().split('.');
        const t = tmp[0].split('T');
        return t[0];
    };

    // Modal deleted
    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
    const handleOpenDeleteModal = () => setOpenDeleteModal(true);
    const handleCloseDeleteModal = () => setOpenDeleteModal(false);

    return (
        <Page title="Affilation">
            <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {loading && <LinearProgress />}


            <Container sx={{ marginTop: { xs: 3 } }}>
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    alignItems={{ xs: 'start', sm: 'center' }}
                    justifyContent="space-between"
                    mb={5}
                    spacing={2}
                >
                    <Typography variant="h3" gutterBottom>
                        Affiliation <br />
                        <Typography variant="body2">
                            Gérez vos partenaires faisant la promotion de vos évènements et générant des ventes
                        </Typography>
                    </Typography>
                </Stack>
                <Box padding={3} sx={{ bgcolor: '#F5EFFA', borderRadius: '7px' }}>
                    <Typography variant='h4'>✨ Boostez vos ventes grace aux influenceurs et partenaire locaux !</Typography>
                    <Typography variant='body2'>Offrez-leur les outils dont ils ont besoin (portail dédié, liens d'affiliation personnalisés, suivi des performances en temps réel) et suivez leurs clics, ventes et revenus.
                    </Typography>
                </Box>
                <iframe width="600px" height="700px" src={`http://localhost:3001/fr/event_embed/${eventID}/`} title="wiget" style={{border: '1px solid gainsboro', borderRadius:'10px'}}/>
            
                <iframe width="600px" height="600px" src={`https://meet.google.com/tnn-husn-scg`} title="wiget" style={{border: '1px solid gainsboro', borderRadius:'10px'}}/>
            
                <Box paddingY={2} sx={{ display: 'flex', justifyContent: 'end' }}>
                    <CustomButton type='primaire' buttonText='Créer une nouvelle affiliation' onClick={() => { navigate('ajout') }} />
                </Box>
                <Box>
                    <Scrollbar>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 500 }} aria-label="caption table">
                                <caption>
                                    {' '}
                                    {affiliations.length === 0
                                        ? "Vous pouvez créer des codes de promotion pour votre événement. Utilisez-les pour offrir une remise sur le prix de vos billets ou dans le cadre de vos opérations marketing ! Vous n'avez pas encore créé de code de promotion. Créez-en un !"
                                        : null}{' '}
                                </caption>
                                <TableHead sx={{ bgcolor: '#f2f2f2' }}>
                                    <TableRow>
                                        <TableCell align="left">Affiliation</TableCell>
                                        <TableCell align="right">Nombre de clicks</TableCell>
                                        <TableCell align="right">Billets vendu</TableCell>
                                        <TableCell align="right">Revenue</TableCell>
                                        <TableCell align="right">Statut</TableCell>
                                    </TableRow>
                                </TableHead>
                                {loading ? (
                                    <caption>
                                        <Skeleton variant="rounded" width="100%" />
                                    </caption>
                                ) : affiliations.length === 0 && !loading ? (
                                    <caption>
                                        <Stack spacing={1} sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                            <Iconify icon="icomoon-free:drawer2" sx={{ width: 30, height: 30 }} />
                                            <Typography variant="caption">Aucune donnée</Typography>
                                        </Stack>
                                    </caption>
                                ) : (
                                    <TableBody>
                                        {affiliations.map((row, index) => (
                                            <TableRow key={row.data().name}>
                                                <TableCell align="left" component="th" scope="row">
                                                    <Stack>
                                                        <Typography variant='subtitle1'>{row.data()?.name}</Typography>
                                                        <Typography variant='body1'>{row.data()?.email}</Typography>
                                                        <Typography variant='body2'>Commission de {row.data().commission?.value} {row.data().commission?.type === 0 ? 'CFA' : '%'}</Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell align="right"><Typography variant='body1'>{row.data().clicks}</Typography></TableCell>
                                                <TableCell align="right">
                                                    <Typography variant='body1'>{row.data().sell}</Typography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Typography variant='body1'>{row.data().revenueGenerated}</Typography>
                                                </TableCell>
                                                <TableCell align="right" >
                                                    <Box>
                                                        {
                                                            row.data().status === 1 ?
                                                                <Box sx={{ borderRadius: '10px', paddingX: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                    <Typography fontSize={11} sx={{ color: '#006832' }} variant='caption'>Validé</Typography>
                                                                </Box>
                                                                :
                                                                <Box sx={{ borderRadius: '10px', paddingX: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                    <Typography fontSize={11} sx={{ color: '#CCA128' }} variant='caption'>Validation encours</Typography>
                                                                </Box>
                                                        }
                                                    </Box>
                                                </TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                </Box>
            </Container>
            <Snackbar
                open={openSnack}
                autoHideDuration={5000}
                onClose={handleCloseSnack}
                message={
                    <Box>
                        {msg.content}{' '}
                        <Iconify
                            sx={{ color: msg.color }}
                            icon={`${msg.red ? 'system-uicons:cross-circle' : 'mdi:success-circle-outline'}`}
                        />
                    </Box>
                }
            />
        </Page>
    );
}
