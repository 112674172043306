/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-await-in-loop */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
// @mui
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Grid,
  Container,
  Typography,
  Box,
  Stack,
  TextField,
  FormControlLabel,
  Switch,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  Paper,
  styled,
  ToggleButtonGroup,
  ToggleButton,
  Autocomplete,
  Chip,
} from '@mui/material';
// components

import { addDoc, collection, doc, getDoc, getDocs, query, setDoc, Timestamp, updateDoc } from 'firebase/firestore';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { db } from '../../firebase.config';

import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import CustomButton from '../../components/CustomButton';
import NumberTextField from '../../components/NumberTextField';
import CustomDateTimePicker from '../../components/CustomDateTimePicker';


const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: 0.5,
    width: 60,
    height: 30,
    border: 0,
    // background: 'black',
    '&.Mui-disabled': {
      border: 0,
    },
    '&.Mui-selected': {
      border: 0,
      background: '#5E17EB',
      color: 'white',
      '&:hover': {
        backgroundColor: '#5E17EB',

      },
    },
    '&:not(:first-of-type)': {
      // background: 'red',
      borderRadius: '7px',
    },
    '&:first-of-type': {
      // background: 'black',
      borderRadius: '7px',
    },
  },
}));
// ----------------------------------------------------------------------

export default function AddDiscount() {
  const { eventID, codeID } = useParams();
  // const theme = useTheme();

  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const [promoInfo, setPromoInfo] = React.useState(); // valeur de la reduction

  // recuperation de billets
  const [tickets, ticketList] = React.useState([]); // contient la liste des tickets
  const [checks, setCheck] = React.useState([]);

  const [selectedTags, setSelectedTags] = React.useState([]);
  const [selectedTagsId, setSelectedTagsId] = React.useState([]);
  const handleTagChange = async (event, value) => {
    setLoading(true)
    const tmp = []
    value.forEach(element => {
      console.log(element.id)
      tmp.push(element.id)
    });
    try {
      setSelectedTagsId(tmp)
      console.log(value)
      setSelectedTags(value);
    } catch (error) {
      console.log(error)
    }
    console.log(selectedTags)
    setLoading(false)
  };

  React.useEffect(() => {
    (async () => {
      ticketList([]);

      try {
        setLoading(true);
        const q = query(collection(db, `evenement/${eventID}/tickets`));
        const snapshot = await getDocs(q);
        const tmp = []
        snapshot.forEach((d) => {
          // ticketList((tickets) => [...tickets, d]);
          tmp.push(d)
        });
          ticketList(tmp);
        setLoading(false);
      } catch (error) {
        console.log('error');
        setLoading(false);
      }
    })();
  }, [eventID]);

  const [codeASaisir, setASaisir] = React.useState(''); // valeur de la reduction
  const [codeError, setCodeError] = React.useState(false); // valeur de la reduction
  const [nbUtilisation, setNb] = React.useState(); // valeur de la reduction
  const [nbError, setNbError] = React.useState(false); // valeur de la reduction
  const [reduction, setReduction] = React.useState(); // valeur de la reduction
  const [reductionError, setReductionError] = React.useState(false);
  const [debutField, setDebut] = React.useState(); // valeur de la reduction
  const [finField, setFin] = React.useState(); // valeur de la reduction
  const [codePromos, setCodePromo] = React.useState([]); // tableau qui coontiendra les code de promotion
  const [codePromosError, setCodePromoError] = React.useState(false);
  const [limitedT, setLimit] = React.useState(false); //
  const [limitedTError, setLimitError] = React.useState(false); //
  const [period, setPeriod] = React.useState(false); // activer un periode de validité
  const [periodError, setPeriodError] = React.useState(false);
  const [reductionType, setReductionType] = React.useState(0); // reduction type


  // programmation
  // date / time
  const [dateDebut, setDateDebut] = React.useState(null);
  const [timeDebut, setTimeDebut] = React.useState('');

  const handleDateDebutChange = (newDate) => {
    setDateDebut(newDate);
  };

  const handleTimeDebutChange = (newTime) => {
    console.log('parent')
    setTimeDebut(newTime);
  };
  const [dateFin, setDateFin] = React.useState(null);
  const [timeFin, setTimeFin] = React.useState('');

  const handleDateFinChange = (newDate) => {
    setDateFin(newDate);
  };

  const handleTimeFinChange = (newTime) => {
    setTimeFin(newTime);
  };

  // convert timestamp

  const handleTimestamp = (e) => {
    console.log('handle time stamp')
    const date = e.toDate();
    return format((new Date(date.toJSON())), 'EEE dd MMM yyyy', { locale: fr }).toString();
  };

  //  snack bar
  const [openSnack, setOpenSnack] = React.useState(false);
  const [msg, setMsg] = React.useState({ content: '', error: false });
  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  const handlePeriod = () => {
    setPeriod((prev) => !prev);
  };

  // creer nos codes de promotion
  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();
    setCodeError(false)
    setCodePromoError(false);
    setReductionError(false);
    setLimitError(false);
    setPeriodError(false);
    setNbError(false);
    let tmp = false;
    console.log('reduction ', reduction);
    const d = Date.now();
    // handleToggle();
    if (codeASaisir === '') {
      setCodeError(true);
      tmp = true;
    }
    if (parseInt(nbUtilisation) > 0) {
      //
    }else {
      setNbError(true);
      tmp = true;
    }
    if (parseInt(reduction) > 0) {
      // 
    }
    else {
      setReductionError(true);
      tmp = true;
    }
    if (limitedT && selectedTagsId.length === 0) {
      setLimitError(true);
      tmp = true;
    }
    
    if (period && (!finField || !debutField)) {
      setPeriodError(true);
      console.log('fin :');
      tmp = true;
    }
    if (tmp) {
      setMsg({
        content: 'Veuillez remplir correctement les informations de votre code promo',
        error: true,
      });
      setOpenSnack(true);
      setLoading(false)
      return;
    }
    let tmpNbUtilisation = nbUtilisation
    let tmpreduction = reduction
    try {
      tmpNbUtilisation = tmpNbUtilisation.replace('e','')
      tmpreduction = tmpreduction.replace('e','')
    } catch (error) {
      console.log('is not a string')
    }
    try {
      // verification de la periode
      if (codeID) {
        await updateDoc(doc(db, `evenement/${eventID}/codePromo`, codeASaisir), {
          // creationDate: name,
          reduc: { type: reductionType, value: parseInt(tmpreduction) >= 100 && reductionType === 0 ? 100 : parseInt(tmpreduction)},
          statut: 1,
          max: parseInt(tmpNbUtilisation),
          billet: limitedT && selectedTagsId.length > 0 ? selectedTagsId : null,
          // debut: debutField ? Timestamp.fromDate(new Date(debutField)) : null,
          // fin: finField ? Timestamp.fromDate(new Date(finField)) : null,
          updateDate: Timestamp.fromDate(new Date(d)),
        });
      } else {
        await setDoc(doc(db, `evenement/${eventID}/codePromo`, codeASaisir), {
          // creationDate: name,
          code: codeASaisir,
          reduc: { type: reductionType, value: parseInt(tmpreduction) >= 100 && reductionType === 0 ? 100 : parseInt(tmpreduction)},
          statut: 1,
          max: parseInt(tmpNbUtilisation),
          now: 0,
          gain: 0,
          cost: 0,
          billet: limitedT && selectedTagsId.length > 0 ? selectedTagsId : null,
          // debut: debutField ? Timestamp.fromDate(new Date(debutField)) : null,
          // fin: finField ? Timestamp.fromDate(new Date(finField)) : null,
          createDate: Timestamp.fromDate(new Date(d)),
        });
      }
      setMsg({ content: 'Le code a été créé avec succès' });
      setOpenSnack(true);
      navigate(-1, { replace: true });
    } catch (error) {
      console.log('error');
      console.log(error.message);
      setMsg({ content: 'une erreur est survenue', error: true });
      setOpenSnack(true);
    }
    setLoading(false)
  };

  // chargement
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    (async () => {
      // ticketList([]);
      setLoading(true);
      try {
        //
        
        const promoInfo = await getDoc(doc(db, `evenement/${eventID}/codePromo`, codeID))
        if (promoInfo.exists()) {
          setPromoInfo(promoInfo.data())
          setASaisir(promoInfo.data().code)
          setNb(promoInfo.data().max)
          setReductionType(promoInfo.data().reduc.type)
          setReduction(promoInfo.data().reduc.value)
          if (promoInfo.data().billet !== null) {
            setLimit(true)
            setSelectedTagsId(promoInfo.data().billet)
          }
        }
        setLoading(false);
      } catch (error) {
        console.log('error');
        console.log(error);
        setLoading(false);
      }
    })();
  }, [codeID, eventID]);

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      const tmpSelectedTags = []
      try {
        //
        if (tickets.length > 0 && codeID && selectedTagsId.length > 0) {
          tickets.forEach((element) => {
            console.log(element)
            if (selectedTagsId.indexOf(element.id) !== -1) {
              tmpSelectedTags.push(element)
              console.log('done')
            }
          });
          setSelectedTags(tmpSelectedTags)
          
        } else{
          console.log('done')
          console.log(codeID)
          console.log(tickets.length)
          console.log(selectedTagsId.length)
        }
        setLoading(false);
      } catch (error) {
        console.log('error');
        console.log(error);
        setLoading(false);
      }
    })();
  }, [tickets, codeID, selectedTagsId]);



  return (
    <Page title="Code de promotion">
      <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container sx={{ marginTop: { xs: 3 } }}>

        <Stack alignItems="start" justifyContent="space-between" mb={5}>
          <Typography variant="h3" gutterBottom>
            Code de promotion
          </Typography>
          <CustomButton
            type='secondaire'
            buttonText='Retour'
            startIcon={<Iconify icon='ion:arrow-back-outline' sx={{ width: 20, height: 20 }} />}
            onClick={() => window.history.back()}
          />
        </Stack>

        <Box paddingBottom={10}>
          <Stack spacing={3}>
            <Box sx={{ border: '2px solid gainsboro', borderRadius: '10px' }} padding={2}>
              <Grid container padding={2}>
                <Grid xs={12} sm={6}>
                  <Stack spacing={1}>
                    <Typography>Code de promotion</Typography>
                    <TextField value={codeASaisir} onChange={(e) => setASaisir(e.target.value)} required type='text' error={codeError} helperText={codeError ? 'Ce champs est requis' : null} />
                  </Stack>
                </Grid>
              </Grid>
              <Grid container padding={2}>
                <Grid xs={12} sm={6}>
                  <Stack spacing={1}>
                    <Typography>Nombre d'utilisation</Typography>
                    <NumberTextField value={nbUtilisation} onChange={(e) => setNb(e)}  required error={nbError} helperText={nbError ? 'Ce champs est requis' : null}/>
                    </Stack>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ border: '2px solid gainsboro', borderRadius: '10px' }} padding={2}>
              <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Typography variant='h4'>Remise</Typography>
              </Stack>

              <Grid container sx={{ bgcolor: '#f2f2f2' }} padding={2}>
                <Grid xs={12} >
                  <Box sx={{ display: 'flex' }}>
                    <Box onClick={() => setReductionType(0)} sx={reductionType === 0 ? { bgcolor: '#F5EFFA', border: '2px solid #5E17EB', borderRadius: '10px', cursor: 'pointer' }
                      :
                      { bgcolor: 'gainsboro', borderRadius: '10px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                      <Typography variant='body2'>Pourcentage</Typography>
                    </Box>
                    <Box padding={0.5} />
                    <Box onClick={() => setReductionType(1)} sx={reductionType === 1 ? { bgcolor: '#F5EFFA', border: '2px solid #5E17EB', borderRadius: '10px', cursor: 'pointer' }
                      :
                      { bgcolor: 'gainsboro', borderRadius: '10px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                      <Typography variant='body2'>Montant fixe</Typography>
                    </Box>
                  </Box>

                </Grid>
                <Grid xs={12} sm={6}>
                  <Stack padding={1} spacing={0.5}>
                    <Typography variant='body1' >{reductionType === 1 ? 'Montant de commission (CFA)' : 'Pourcentage de commission (%)'}</Typography>
                    <NumberTextField value={reduction} onChange={(e) => setReduction(e)} error={reductionError} helperText={reductionError ? 'Ce champs est requis' : null}/>
                    <Box>
                      <Typography variant='body2'>le client aura une reduction de <strong>{reduction} {reductionType === 1 ? 'CFA' : '%'}</strong> sur chaque achat</Typography>
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ border: '2px solid gainsboro', borderRadius: '10px' }} padding={2}>
              <Stack justifyContent='space-between' alignItems='start'>
                <Typography variant='h4'>Billet</Typography>
                <Typography variant='caption'>Ce code peut-être appliqué sur :</Typography>
              </Stack>

              <Grid container sx={{ bgcolor: '#f2f2f2' }} padding={2}>
                <Grid xs={12} >
                  <Box sx={{ display: 'flex' }}>
                    <Box onClick={() => setLimit(false)} sx={!limitedT ? { bgcolor: '#F5EFFA', border: '2px solid #5E17EB', borderRadius: '10px', cursor: 'pointer' }
                      :
                      { bgcolor: 'gainsboro', borderRadius: '10px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                      <Typography variant='body2'>Tous les billets</Typography>
                    </Box>
                    <Box padding={0.5} />
                    <Box onClick={() => setLimit(true)} sx={limitedT ? { bgcolor: '#F5EFFA', border: '2px solid #5E17EB', borderRadius: '10px', cursor: 'pointer' }
                      :
                      { bgcolor: 'gainsboro', borderRadius: '10px', cursor: 'pointer' }} paddingX={2} paddingY={1.25} >
                      <Typography variant='body2'>Billets spécifiques</Typography>
                    </Box>
                  </Box>

                </Grid>
                {
                  limitedT && <Grid xs={12} sm={6}>
                    <Stack paddingY={1} >
                      <Autocomplete
                        multiple
                        id="tags-standard"
                        options={tickets}
                        getOptionLabel={(ticket) => ticket?.data()?.ticket_name}
                        value={selectedTags}
                        onChange={handleTagChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder={selectedTags.length > 0 ? '' : 'Choisisser au moins un ticket'}
                            error={limitedTError}
                            multiline
                            helperText={limitedTError ? 'choisisser au moins un ticket' : ''}
                          />
                        )}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((ticket, index) => (
                            <Chip
                              sx={{ bgcolor: '#F5EFFA' }}
                              key={ticket?.id}
                              label={ticket?.data()?.ticket_name}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                      />
                    </Stack>
                  </Grid>
                }

              </Grid>
            </Box>

          </Stack>

        </Box>

        <Container>
          <Stack
            direction='row'
            spacing={2}
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: 'white',
              padding: '16px',
              // borderTop: '2px solid gainsboro',
              boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
              zIndex: 1000,
              display: 'flex',
              justifyContent: 'right',
              alignItems: 'center',
            }}
          >
            <CustomButton
              loading={loading}
              type='secondaire'
              buttonText='Annuler'
              onClick={() => window.history.back()}
            />
            <CustomButton
              loading={loading}
              type='primaire'
              buttonText={codeID ? 'Enregistrer' : 'Crééer'}
              onClick={handleSubmit}
            />
          </Stack>
        </Container>
        <Snackbar
          open={openSnack}
          autoHideDuration={5000}
          onClose={handleCloseSnack}
          message={
            <Box>
              {msg.content}{' '}
              <Iconify
                sx={{ color: msg.error ? 'red' : 'white' }}
                icon={`${msg.error ? 'system-uicons:cross-circle' : 'mdi:success-circle-outline'}`}
              />
            </Box>
          }
        />
      </Container>
    </Page>
  );
}
