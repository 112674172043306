// component
import { useParams } from 'react-router-dom';
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;



const navConfigMetrics = [
  {
    title: 'Ventes',
    path: 'analyse/vente',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Communauté',
    path: '',
    icon: getIcon(''),
  },
  {
    title: 'Vue d\'ensemble',
    path: 'analyse/communaute',
    icon: getIcon('clarity:settings-solid'),
  },
  {
    title: 'Comportement d\'achat',
    path: 'analyse/comportment_vente',
    icon: getIcon('ep:ticket'),
  },
  {
    title: 'Socio-demographique',
    path: 'analyse/socio_demographique',
    icon: getIcon('ep:ticket'),
  },
  {
    title: 'Goûts musicaux',
    path: 'analyse/gout',
    icon: getIcon('fontisto:date'),
  },
 
];

export default navConfigMetrics;
